import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { FaEye, FaEyeSlash, FaTimes } from 'react-icons/fa';
import {
  HiOutlineUser,
  HiOutlineMail,
  HiOutlineLockClosed,
  HiX
} from 'react-icons/hi';

const CreateAdminModal = ({ onAdminCreated, orgId }) => {
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [role, setRole] = useState('ADMIN');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        setShowModal(false);
      }
    };

    if (showModal) {
      window.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [showModal]);

  const handleValidationError = (errorMessage) => {
    if (errorMessage.includes('name')) {
      return 'Invalid name provided.';
    } else if (errorMessage.includes('email')) {
      return 'Invalid email provided.';
    } else if (errorMessage.includes('password')) {
      return 'Invalid password provided.';
    }
    return 'Validation error occurred.';
  };

  const handleClose = () => {
    if (!loading) {
      setShowModal(false);
    }
  };

  const handleShow = () => {
    setShowModal(true);
  };

  const createAdmin = async () => {
    let hasError = false;

    if (!name.trim()) {
      toast.error("Admin name can't be empty");
      hasError = true;
    } else if (!email.trim()) {
      toast.error("Email can't be empty");
      hasError = true;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      toast.error('Email is invalid');
      hasError = true;
    } else if (!password.trim()) {
      toast.error("Password can't be empty");
      hasError = true;
    } else if (password.length < 6) {
      toast.error('Password must be at least 6 characters long');
      hasError = true;
    }

    if (hasError) {
      return;
    }

    if (!orgId) {
      toast.error('Organization ID is missing');
      return;
    }

    const superAdminUser = JSON.parse(localStorage.getItem('superAdminUser'));
    const accessToken = superAdminUser?.session?.accessToken || null;

    if (!accessToken) {
      throw new Error('Access token not found in local storage');
    }

    setLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_DEV_API}/v1/organization/${orgId}/user`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ name, email, password, role })
        }
      );

      const data = await response.json();

      if (response.ok) {
        // onAdminCreated(data.data);
        setName('');
        setEmail('');
        setPassword('');
        handleClose();
        toast.success('Admin created successfully');
        if (onAdminCreated) {
          onAdminCreated();
        }
        navigate(`/super-admin/edit-organisation/${orgId}`);
      } else {
        if (
          data.message &&
          data.message.includes('E11000 duplicate key error')
        ) {
          if (data.message.includes('name_1 dup key')) {
            toast.error(`An Admin with the name "${name}" already exists.`);
          } else if (data.message.includes('email_1 dup key')) {
            toast.error(`An Admin with the email "${email}" already exists.`);
          } else {
            toast.error('This admin already exists.');
          }
        } else {
          const errorMessage = handleValidationError(data.message);
          toast.error(errorMessage);
        }
      }
    } catch (error) {
      toast.error(error.message || 'An unknown error occurred');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <style>
        {`
        .admin-modal {
          backdrop-filter: blur(8px);
        }

        .admin-modal .modal-content {
          border: none;
          border-radius: 16px;
          box-shadow: 0 8px 32px rgba(0, 0, 0, 0.08);
          background: linear-gradient(145deg, #ffffff, #f8fafc);
        }

        .admin-modal-header {
          position: relative;
          padding: 2rem 2rem 1rem;
          border: none;
          background: transparent;
        }

        .admin-modal-title {
          font-size: 1.5rem;
          font-weight: 600;
          color: #1e293b;
          margin-bottom: 0.5rem;
        }

        .admin-modal-subtitle {
          font-size: 0.875rem;
          color: #64748b;
        }

        .admin-modal-close {
          position: absolute;
          top: 1.5rem;
          right: 1.5rem;
          padding: 0.5rem;
          border-radius: 50%;
          border: none;
          background: #f1f5f9;
          color: #64748b;
          transition: all 0.2s ease;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .admin-modal-close:hover {
          background: #fee2e2;
          color: #ef4444;
          transform: rotate(90deg);
        }

        .admin-modal-body {
          padding: 1rem 2rem 2rem;
        }

        .admin-form-group {
          margin-bottom: 1.5rem;
          position: relative;
        }

        .admin-form-label {
          display: block;
          font-size: 0.875rem;
          font-weight: 500;
          color: #475569;
          margin-bottom: 0.5rem;
        }

        .admin-form-control {
          width: 100%;
          padding: 0.75rem 1rem 0.75rem 2.5rem;
          border: 1px solid #e2e8f0;
          border-radius: 12px;
          font-size: 0.875rem;
          color: #1e293b;
          transition: all 0.2s ease;
          background: #ffffff;
        }

        .admin-form-control:focus {
          border-color: #818cf8;
          box-shadow: 0 0 0 4px rgba(129, 140, 248, 0.1);
          outline: none;
        }

        .admin-form-icon {
          position: absolute;
          left: 0.75rem;
          top: 2.5rem;
          color: #94a3b8;
          pointer-events: none;
        }

        .admin-form-password {
          position: relative;
        }

        .password-toggle {
          position: absolute;
          right: 1rem;
          top: 2.25rem;
          background: transparent;
          border: none;
          color: #94a3b8;
          padding: 0.25rem;
          cursor: pointer;
          transition: color 0.2s ease;
        }

        .password-toggle:hover {
          color: #64748b;
        }

        .admin-modal-footer {
          padding: 1.5rem 2rem;
          border-top: 1px solid #f1f5f9;
          display: flex;
          justify-content: flex-end;
          gap: 1rem;
        }

        .btn-cancel {
          padding: 0.75rem 1.5rem;
          border-radius: 10px;
          border: 1px solid #e2e8f0;
          background: #ffffff;
          color: #64748b;
          font-weight: 500;
          transition: all 0.2s ease;
        }

        .btn-cancel:hover {
          background: #f1f5f9;
          color: #475569;
        }

        .btn-create {
          padding: 0.75rem 1.5rem;
          border-radius: 10px;
          border: none;
          background: linear-gradient(135deg, #818cf8 0%, #6366f1 100%);
          color: white;
          font-weight: 500;
          transition: all 0.2s ease;
        }

        .btn-create:hover {
          transform: translateY(-1px);
          box-shadow: 0 4px 12px rgba(99, 102, 241, 0.2);
        }

        .btn-create:active {
          transform: translateY(0);
        }

        .btn-create.loading {
          opacity: 0.7;
          pointer-events: none;
        }

        @keyframes slideIn {
          from {
            opacity: 0;
            transform: translateY(-10px);
          }
          to {
            opacity: 1;
            transform: translateY(0);
          }
        }

        .admin-modal .modal-content {
          animation: slideIn 0.3s ease-out;
        }
      `}
      </style>

      <Button
        variant="primary"
        onClick={handleShow}
        style={{ marginTop: '2vh' }}
        className="btn-sm"
      >
        Add Admin
      </Button>

      <Modal
        show={showModal}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
        className="admin-modal"
      >
        <div className="admin-modal-header">
          <div>
            <h4 className="admin-modal-title">Create a New Admin</h4>
            <p className="admin-modal-subtitle">
              Add an administrator to manage this organization
            </p>
          </div>
          <button className="admin-modal-close" onClick={handleClose}>
            <HiX size={18} />
          </button>
        </div>

        <div className="admin-modal-body">
          <div className="admin-form-group">
            <label className="admin-form-label">
              Name<span className="text-danger">*</span>
            </label>
            <div className="admin-form-icon">
              <HiOutlineUser size={18} />
            </div>
            <input
              type="text"
              className="admin-form-control"
              placeholder="Enter admin name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              disabled={loading}
            />
          </div>

          <div className="admin-form-group">
            <label className="admin-form-label">
              Email<span className="text-danger">*</span>
            </label>
            <div className="admin-form-icon">
              <HiOutlineMail size={18} />
            </div>
            <input
              type="email"
              className="admin-form-control"
              placeholder="Enter admin email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              disabled={loading}
            />
          </div>

          <div className="admin-form-group">
            <label className="admin-form-label">
              Password<span className="text-danger">*</span>
            </label>
            <div className="admin-form-icon">
              <HiOutlineLockClosed size={18} />
            </div>
            <input
              type={showPassword ? 'text' : 'password'}
              className="admin-form-control"
              placeholder="Enter password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              disabled={loading}
            />
            <button
              type="button"
              className="password-toggle"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? <FaEyeSlash size={18} /> : <FaEye size={18} />}
            </button>
          </div>
        </div>

        <div className="admin-modal-footer">
          <button
            className="btn-cancel"
            onClick={handleClose}
            disabled={loading}
          >
            Cancel
          </button>
          <button
            className={`btn-create ${loading ? 'loading' : ''}`}
            onClick={createAdmin}
            disabled={loading}
          >
            {loading ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className="me-2"
                />
                Creating...
              </>
            ) : (
              'Create Admin'
            )}
          </button>
        </div>
      </Modal>
    </>
  );
};

export default CreateAdminModal;
