import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import moment from 'moment';
import Accordion from 'react-bootstrap/Accordion';
import { Button, Spinner } from 'react-bootstrap';
import { ReactMultiEmail } from 'react-multi-email';
import 'react-multi-email/dist/style.css';
import {
  FaTimes,
  FaUserPlus,
  FaPaperPlane,
  FaInfoCircle,
  FaUsers,
  FaFileAlt,
  FaAngleLeft,
  FaAngleRight
} from 'react-icons/fa';
import './personality-test.css';
import { FaAngleDown } from 'react-icons/fa6';
const PersonalityTest = () => {
  const [email, setEmail] = useState('');
  const [emails, setEmails] = useState([]);
  const [disc, setDisc] = useState(true);
  const [fetchUsersLoading, setFetchUsersLoading] = useState(false);
  const [motivators, setMotivators] = useState(true);
  const [hvpPlus, setHvpPlus] = useState(true);
  const [invitedUsers, setInvitedUsers] = useState([]);
  const [totalResults, setTotalResults] = useState(0);
  const [search, setSearch] = useState('');
  const [limit, setLimit] = useState(10);
  const [reInvLoading, setReInvLoading] = useState(-1); //Resend invitation loader item index
  const [invLoading, setInvLoading] = useState(false); //Send invitation loader flag
  const [sortBy, setSortBy] = useState('createdAt');
  const [sortOrder, setSortOrder] = useState('desc');
  const [status, setStatus] = useState('');
  const [jobId, setJobId] = useState('');
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const userDetails = JSON.parse(localStorage.getItem('userDetails'));
  const accessToken = localStorage.getItem('userDetails')
    ? JSON.parse(localStorage.getItem('userDetails')).session.accessToken
    : null;
  const orgId = userDetails?.user?.organizationId;
  const [activeKey, setActiveKey] = useState(
    localStorage.getItem('accordionKey') || '0'
  );

  const fetchInvitedUsersList = async () => {
    let url = `${process.env.REACT_APP_DEV_API}/v1/organization/${orgId}/personality-test`;
    let params = '?';
    if (currentPage) {
      params += `page=${currentPage}&`;
    }
    if (limit) {
      params += `limit=${limit}&`;
    }
    if (search) {
      params += `search=${search}&`;
    }
    if (sortBy) {
      params += `sortBy=${sortBy}&`;
    }
    if (sortOrder) {
      params += `sortOrder=${sortOrder}&`;
    }
    if (status && status.trim() != '') {
      params += `status=${status}&`;
    }
    if (jobId && jobId.trim() != '') {
      params += `jobId=${jobId}&`;
    }
    if (email && email.trim() != '') {
      params += `email=${email}&`;
    }

    try {
      setFetchUsersLoading(true);
      const response = await axios.get(`${url}${params}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`
        }
      });
      setFetchUsersLoading(false);
      if (response.data.statusCode === 200) {
        setCurrentPage(response.data.currentPage);
        setTotalResults(response.data.total);
        setTotalPages(response.data.totalPages);
        setInvitedUsers(response.data.data);
      }
    } catch (error) {
      setFetchUsersLoading(false);
      console.log(error);
    }
  };

  const inviteUser = async () => {
    try {
      setInvLoading(true);
      let testTypes = [];
      if (disc) {
        testTypes.push('DISC');
      }
      if (motivators) {
        testTypes.push('MOTIVATORS');
      }
      if (hvpPlus) {
        testTypes.push('HVP_PLUS');
      }
      if (testTypes.length === 0) {
        toast.error('Please select atleast one Personality test');
        return;
      }
      const payload = {
        emails: emails,
        testTypes: testTypes
      };
      const response = await axios.post(
        `${process.env.REACT_APP_DEV_API}/v1/organization/${orgId}/personality-test/invite`,
        payload,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`
          }
        }
      );
      if (response.data.statusCode === 200) {
        if (response.data.message === 'INVITATIONS_SENT_SUCCESSFULLY') {
          toast.success('Invitation sent successfully');
          setEmails([]);
          fetchInvitedUsersList();
        }
      }
      setInvLoading(false);
    } catch (error) {
      if (error.response.status === 400) {
        toast.error(error.response.data.details);
      }
      console.log(error);
      setInvLoading(false);
    }
  };

  const resendInvite = async (testId) => {
    try {
      const payload = {
        testId
      };
      const response = await axios.post(
        `${process.env.REACT_APP_DEV_API}/v1/organization/${orgId}/personality-test/resend-invite`,
        payload,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`
          }
        }
      );
      if (response.data.statusCode === 200) {
        if (response.data.message === 'INVITATION_RESENT_SUCCESSFULLY') {
          toast.success('Invitation resent successfully');
        }
      }
      setReInvLoading(-1);
    } catch (error) {
      console.log(error);
      setReInvLoading(-1);
    }
  };

  const handleToggle = (key) => {
    setActiveKey(activeKey === key ? '-1' : key);
  };

  const formatText = (text) => {
    //replace underscore with space
    return text.replace(/_/g, ' ');
  };

  useEffect(() => {
    localStorage.setItem('accordionKey', activeKey);
  }, [activeKey]);

  useEffect(() => {
    fetchInvitedUsersList();
  }, [currentPage]);

  const handleOpenReport = (e, user) => {
    e.preventDefault();
    e.stopPropagation();
    if (user?.discTest?.status && user.discTest.status === 'COMPLETED') {
      window.open(
        `${window.location.origin}/job-disc-view/${user._id}`,
        '_blank'
      );
    }
    if (
      user?.motivatorsTest?.status &&
      user.motivatorsTest.status === 'COMPLETED'
    ) {
      window.open(
        `${window.location.origin}/motivators-report/${user._id}`,
        '_blank'
      );
    }
    if (user?.hvpPlusTest?.status && user.hvpPlusTest.status === 'COMPLETED') {
      window.open(
        `${window.location.origin}/job-hvp-view/${user._id}`,
        '_blank'
      );
    }
  };

  const isPreviousButtonDisabled =
    !totalResults || fetchUsersLoading || currentPage === 1;
  const isNextButtonDisabled =
    !totalResults || fetchUsersLoading || currentPage === totalPages;

  const isFormValid = () => {
    return emails.length > 0 && (disc || motivators || hvpPlus);
  };

  return (
    <div className="container">
      <section className="page-header-section">
        <div className="page-header-overlay"></div>
        <div className="page-header-accents"></div>
        <div className="page-title-wrapper">
          <h1 className="page-title">Invite Users for Personality Tests</h1>
        </div>
      </section>
      <Accordion
        defaultActiveKey={activeKey}
        className="mb-5 personality-accordion"
        id="personality-test"
      >
        <Accordion.Item eventKey="0">
          <Accordion.Header onClick={() => handleToggle('0')}>
            <div className="w-100 d-flex justify-content-between align-items-center">
              <span className="fw-bold h5 mb-0">
                Why Personality Tests Matter
              </span>
              <FaAngleDown
                className={`accordion-icon ${activeKey === '0' ? 'rotated' : ''}`}
                size={20}
              />
            </div>
          </Accordion.Header>
          <Accordion.Body>
            <p className="lead text-muted mb-4">
              Personality tests provide deep insights into a candidate's
              behavioral tendencies, leadership potential, teamwork
              capabilities, and overall fit within your organization.
            </p>

            <div className="personality-cards-grid">
              <div className="personality-test-card disc">
                <h3>DISC</h3>
                <p>
                  Assesses a candidate's dominant behavioral style, helping you
                  understand how they interact with others, handle conflict, and
                  approach tasks.
                </p>
              </div>

              <div className="personality-test-card motivators">
                <h3>Motivators</h3>
                <p>
                  Identifies what drives a candidate, revealing their key
                  motivators and whether they align with your company culture.
                </p>
              </div>

              <div className="personality-test-card hvp">
                <h3>HVP Plus</h3>
                <p>
                  Measures a candidate's values and decision-making processes,
                  providing insights into their ethical stance and
                  problem-solving approach.
                </p>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      {/* Invite New User Section */}
      <div className="invite-section">
        <div className="invite-header">
          <h2 className="invite-title">
            <FaUserPlus size={24} />
            Invite New User
          </h2>
        </div>

        <div className="invite-body">
          <div className="form-group">
            <label className="form-label">Email Addresses</label>
            <ReactMultiEmail
              placeholder="Enter email addresses..."
              emails={emails}
              onChange={(_emails) => setEmails(_emails)}
              getLabel={(email, index, removeEmail) => (
                <div data-tag key={index}>
                  {email}
                  <span data-tag-handle onClick={() => removeEmail(index)}>
                    ×
                  </span>
                </div>
              )}
              style={{ minHeight: '120px' }}
            />
            <div className="helper-text">
              <FaInfoCircle size={14} />
              <span>
                Enter one or multiple email addresses to send test invitations
              </span>
            </div>
          </div>

          <div className="test-selection">
            <div className="test-selection-header">
              <label className="form-label">Select Personality Tests</label>
            </div>

            <div className="test-options">
              <div
                className={`test-option ${disc ? 'selected' : ''}`}
                onClick={() => setDisc(!disc)}
              >
                <div className="test-checkbox-wrapper">
                  <input
                    type="checkbox"
                    id="disc-test"
                    checked={disc}
                    onChange={(e) => setDisc(e.target.checked)}
                    className="test-checkbox"
                    onClick={(e) => e.stopPropagation()}
                  />
                  <div className="checkbox-custom"></div>
                </div>
                <label
                  htmlFor="disc-test"
                  className="test-label"
                  onClick={(e) => e.preventDefault()}
                >
                  DISC
                </label>
              </div>

              <div
                className={`test-option ${motivators ? 'selected' : ''}`}
                onClick={() => setMotivators(!motivators)}
              >
                <div className="test-checkbox-wrapper">
                  <input
                    type="checkbox"
                    id="motivators-test"
                    checked={motivators}
                    onChange={(e) => setMotivators(e.target.checked)}
                    className="test-checkbox"
                    onClick={(e) => e.stopPropagation()}
                  />
                  <div className="checkbox-custom"></div>
                </div>
                <label
                  htmlFor="motivators-test"
                  className="test-label"
                  onClick={(e) => e.preventDefault()}
                >
                  Motivators
                </label>
              </div>

              <div
                className={`test-option ${hvpPlus ? 'selected' : ''}`}
                onClick={() => setHvpPlus(!hvpPlus)}
              >
                <div className="test-checkbox-wrapper">
                  <input
                    type="checkbox"
                    id="hvp-test"
                    checked={hvpPlus}
                    onChange={(e) => setHvpPlus(e.target.checked)}
                    className="test-checkbox"
                    onClick={(e) => e.stopPropagation()}
                  />
                  <div className="checkbox-custom"></div>
                </div>
                <label
                  htmlFor="hvp-test"
                  className="test-label"
                  onClick={(e) => e.preventDefault()}
                >
                  HVP Plus
                </label>
              </div>
            </div>
          </div>

          <div className="invite-actions">
            <button
              className="btn-invite"
              onClick={inviteUser}
              disabled={!isFormValid() || invLoading}
            >
              {invLoading ? (
                <>
                  <Spinner animation="border" size="sm" />
                  <span>Sending Invitations...</span>
                </>
              ) : (
                <>
                  <FaPaperPlane size={16} />
                  <span>Send Invitations</span>
                </>
              )}
            </button>
          </div>
        </div>
      </div>

      {/* List of Invited Users */}
      <div className="invited-users-section">
        <div className="invited-users-header">
          <h2 className="invited-users-title">
            <FaUsers size={22} />
            Invited Users
          </h2>
        </div>

        <div className="invited-users-body">
          {invitedUsers.length > 0 ? (
            <>
              <div className="table-container">
                <table className="users-table">
                  <thead>
                    <tr>
                      <th>Email</th>
                      <th>Date Invited</th>
                      <th>Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {invitedUsers.map((user, index) => (
                      <tr key={user.email + index}>
                        <td>{user.email}</td>
                        <td>{moment(user.invitedAt).format('MMMM D, YYYY')}</td>
                        <td>
                          <span
                            className={`status-badge ${user.overallStatus
                              .toLowerCase()
                              .replace('_', '-')}`}
                            style={{ whiteSpace: 'normal', minWidth: '10rem' }}
                          >
                            {formatText(user.overallStatus)}
                          </span>
                        </td>
                        <td>
                          {user.overallStatus === 'COMPLETED' ? (
                            <button
                              className="action-btn view-report-btn"
                              onClick={(e) => handleOpenReport(e, user)}
                            >
                              <FaFileAlt size={14} />
                              View Report
                            </button>
                          ) : user.overallStatus === 'NOT_STARTED' ? (
                            <button
                              className="action-btn resend-invite-btn"
                              onClick={() => {
                                setReInvLoading(index);
                                resendInvite(user._id);
                              }}
                              disabled={reInvLoading === index}
                            >
                              {reInvLoading === index ? (
                                <>
                                  <Spinner animation="border" size="sm" />
                                  Resending...
                                </>
                              ) : (
                                <>
                                  <FaPaperPlane size={14} />
                                  Resend Invite
                                </>
                              )}
                            </button>
                          ) : null}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              <div className="pagination-container">
                <span className="pagination-info">
                  Showing page {currentPage} of {totalPages} • {totalResults}{' '}
                  total results
                </span>
                <div className="pagination-buttons">
                  <button
                    className="pagination-btn"
                    onClick={() => setCurrentPage(currentPage - 1)}
                    disabled={isPreviousButtonDisabled}
                  >
                    <FaAngleLeft size={14} />
                    Previous
                  </button>
                  <button
                    className="pagination-btn"
                    onClick={() => setCurrentPage(currentPage + 1)}
                    disabled={isNextButtonDisabled}
                  >
                    Next
                    <FaAngleRight size={14} />
                  </button>
                </div>
              </div>
            </>
          ) : (
            <div className="empty-state">
              <div className="empty-state-icon">
                <FaUsers size={28} />
              </div>
              <h3 className="empty-state-title">No Invited Users Found</h3>
              <p className="empty-state-description">
                Start by inviting users to take personality tests using the form
                above. Their results will appear here once invited.
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PersonalityTest;
