import React, { useReducer, useContext, useEffect, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Collapse, Dropdown } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { MenuList } from './Menu';
import '../../../css/sidebar.css';
import { MenuSuperAdmin } from './MenuSuperAdmin';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import { ThemeContext } from '../../../context/ThemeContext';
import LogoutPage from './Logout';

const SideBar = ({ state, setState, handleActive, closeSidebar }) => {
  let d = new Date();
  const {
    iconHover,
    sidebarposition,
    headerposition,
    sidebarLayout,
    ChangeIconSidebar
  } = useContext(ThemeContext);

  const [hideOnScroll, setHideOnScroll] = useState(true);
  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = currPos.y > prevPos.y;
      if (isShow !== hideOnScroll) {
        setHideOnScroll(isShow);
      }
    },
    [hideOnScroll]
  );

  const handleMenuActive = (status, parent) => {
    setState({ active: parent, activeMenu: status });
  };

  const handleSubmenuActive = (status) => {
    setState({ activeSubmenu: status });
    if (state.activeSubmenu === status) {
      setState({ activeSubmenu: '' });
    }
  };

  let path = window.location.pathname;
  path = path.split('/');
  path = path[path.length - 1];

  const location = useLocation();
  const pathToCheck = location.pathname.split('/')[1];

  // Determine which menu to use based on pathToCheck
  const currentMenu = pathToCheck === 'super-admin' ? MenuSuperAdmin : MenuList;

  useEffect(() => {
    let activeName = '';
    let activeMenuName = '';

    currentMenu.forEach((menuItem) => {
      // Main menu item match
      if (location.pathname === `/${menuItem.to}`) {
        activeName = menuItem.title;
      }

      // Submenu match
      if (menuItem.content) {
        menuItem.content.forEach((submenuItem) => {
          if (location.pathname.includes(submenuItem.to)) {
            activeName = menuItem.title;
            activeMenuName = submenuItem.title;
          }
        });
      }
    });

    // Default to Dashboard if no match is found
    if (location.pathname === '/' || (!activeName && !activeMenuName)) {
      activeName = 'Dashboard';
    }
    console.log('activeName: ', activeName, activeMenuName);

    const billingIcon = document.getElementById('billing-icon');
    if (billingIcon) {
      if (activeName === 'Billing') {
        billingIcon.style.color = '';
      } else {
        billingIcon.style.color = '#96a0af';
      }
    }

    // Update active state
    handleActive(activeName, activeMenuName);
  }, [location, currentMenu]);

  const handleLinkClick = (e, title, parentTitle = null) => {
    e.preventDefault(); // Prevent default touch/click behavior
    const href = e.currentTarget.href; // Store href value
    handleActive(title, parentTitle);
    closeSidebar();
    // Force navigation after state updates
    setTimeout(() => {
      window.location.href = href; // Use stored href value
    }, 0);
  };

  return (
    <div
      onMouseEnter={() => ChangeIconSidebar(true)}
      onMouseLeave={() => ChangeIconSidebar(false)}
      className={`dlabnav ${iconHover} ${
        sidebarposition.value === 'fixed' &&
        sidebarLayout.value === 'horizontal' &&
        headerposition.value === 'static'
          ? hideOnScroll > 120
            ? 'fixed'
            : ''
          : ''
      }`}
    >
      <PerfectScrollbar
        className="dlabnav-scroll"
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between'
        }}
      >
        <ul className="metismenu" id="menu" style={{ minHeight: '66vh' }}>
          {currentMenu.map((data, index) => {
            let menuClass = data.classsChange;
            if (menuClass === 'menu-title') {
              return (
                <li className={menuClass} key={index}>
                  {data.title}
                </li>
              );
            } else {
              return (
                <li
                  className={` ${
                    state.active === data.title ? 'mm-active' : ''
                  }`}
                  key={index}
                >
                  {data.content && data.content.length > 0 ? (
                    <>
                      <Link
                        to={data.content[0].to}
                        onClick={(e) => {
                          e.currentTarget.blur();
                          handleLinkClick(e, data.title, data.content[0].title);
                        }}
                        onTouchStart={(e) => {
                          e.currentTarget.click(); // Trigger click on touch start
                        }}
                      >
                        {data.iconStyle}
                        <span className="nav-text">{data.title}</span>
                        {data.update && (
                          <span className="badge badge-xs style-1 badge-danger">
                            {data.update}
                          </span>
                        )}
                      </Link>
                      <ul
                        className={`${
                          menuClass === 'mm-collapse' ? 'mm-show' : ''
                        }`}
                        style={{ top: '0px', bottom: 'auto' }}
                      >
                        {data.content &&
                          data.content.map((subData, index) => {
                            return (
                              <li
                                key={index}
                                className={`${
                                  state.activeSubmenu === subData.title
                                    ? 'mm-active'
                                    : ''
                                }`}
                              >
                                {subData.content &&
                                subData.content.length > 0 ? (
                                  <>
                                    <Link
                                      to={subData.to}
                                      className={
                                        subData.hasMenu ? 'has-arrow' : ''
                                      }
                                      onClick={(e) => {
                                        e.currentTarget.blur();
                                        handleSubmenuActive(subData.title);
                                        closeSidebar();
                                      }}
                                    >
                                      {subData.title}
                                    </Link>
                                    <Collapse
                                      in={
                                        state.activeSubmenu === subData.title
                                          ? true
                                          : false
                                      }
                                    >
                                      <ul
                                        className={`${
                                          menuClass === 'mm-collapse'
                                            ? 'mm-show'
                                            : ''
                                        }`}
                                      >
                                        {subData.content &&
                                          subData.content.map((d, index) => {
                                            return (
                                              <>
                                                <li key={index}>
                                                  <Link
                                                    className={`${
                                                      path === d.to
                                                        ? 'mm-active'
                                                        : ''
                                                    }`}
                                                    to={d.to}
                                                    onClick={(e) => {
                                                      e.currentTarget.blur();
                                                    }}
                                                  >
                                                    {d.title}
                                                  </Link>
                                                </li>
                                              </>
                                            );
                                          })}
                                      </ul>
                                    </Collapse>
                                  </>
                                ) : (
                                  <Link
                                    className={`${
                                      state.activeMenu === subData.title
                                        ? 'mm-active'
                                        : ''
                                    }`}
                                    to={subData.to}
                                    onClick={(e) => {
                                      e.currentTarget.blur();
                                      handleMenuActive(
                                        subData.title,
                                        data.title
                                      );
                                      closeSidebar();
                                    }}
                                  >
                                    {subData.title}
                                  </Link>
                                )}
                              </li>
                            );
                          })}
                      </ul>
                    </>
                  ) : (
                    <Link
                      to={data.to}
                      onClick={(e) => {
                        e.currentTarget.blur();
                        handleLinkClick(e, data.title);
                      }}
                      onTouchStart={(e) => {
                        e.currentTarget.click(); // Trigger click on touch start
                      }}
                    >
                      {data.iconStyle}
                      <span className="nav-text">{data.title}</span>
                    </Link>
                  )}
                </li>
              );
            }
          })}
        </ul>

        {iconHover !== 'icon-hover' && (
          <div className="sidebar-footer">
            <div className="logo-section">
              <div className="logo-wrapper">
                <div className="logo-container">
                  <span className="logo-text">Talent</span>
                  <span className="logo-text accent">Swap</span>
                  <div className="logo-shine"></div>
                </div>
                <div className="logo-backdrop"></div>
              </div>
            </div>
            <div className="divider">
              <span className="divider-line"></span>
              <span className="divider-icon">●</span>
              <span className="divider-line"></span>
            </div>
            <div className="copyright-section">
              <p className="copyright-text">
                <span className="copyright-year">{d.getFullYear()}</span>
                <span className="copyright-symbol">©</span>
                <span className="copyright-rights">All Rights Reserved</span>
              </p>
              <Link to="/privacy-policy" className="privacy-link">
                Privacy Policy
              </Link>
            </div>
          </div>
        )}
      </PerfectScrollbar>
    </div>
  );
};

export default SideBar;
