import React, { useEffect, useState } from 'react';
import { Container, Form, Button, Card, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';

const defaultAptitudeConfig = {
  threshold: 70,
  timeLimit: 120,
  numQuestions: 24,
  questionsConfig: {
    QuantitativeAptitude: {
      numQuestions: 6,
      difficultyDistribution: {
        easy: 2,
        medium: 2,
        hard: 2
      }
    },
    LogicalReasoning: {
      numQuestions: 6,
      difficultyDistribution: {
        easy: 2,
        medium: 2,
        hard: 2
      }
    },
    verbalAbility: {
      numQuestions: 6,
      difficultyDistribution: {
        easy: 2,
        medium: 2,
        hard: 2
      }
    },
    situationalJudgment: {
      numQuestions: 6,
      difficultyDistribution: {
        easy: 2,
        medium: 2,
        hard: 2
      }
    }
  }
};

function AdvancedJobConfig({
  setTestProcesses,
  testProcesses,
  loading,
  setLoading
}) {
  const navigate = useNavigate();

  const [mcqConfigVisible, setMcqConfigVisible] = useState(false);
  const [aptMcqConfigVisible, setAptMcqConfigVisible] = useState(false);
  const [quantAptVisible, setQuantAptVisible] = useState(false);
  const [logicalAptVisible, setLogicalAptVisible] = useState(false);
  const [verbalAptVisible, setVerbalAptVisible] = useState(false);
  const [situationalJudgementVisible, setSituationalJudgementVisible] =
    useState(false);
  const [personalityConfigVisible, setPersonalityConfigVisible] =
    useState(false);
  const [verbalAptitudeConfigVisible, setVerbalAptitudeConfigVisible] =
    useState(false);
  const [numericalAptitudeConfigVisible, setNumericalAptitudeConfigVisible] =
    useState(false);
  const [logicalAptitudeConfigVisible, setLogicalAptitudeConfigVisible] =
    useState(false);
  const [languageEfficiencyConfigVisible, setLanguageEfficiencyConfigVisible] =
    useState(false);
  const [hasError, setHasError] = useState(false);

  const [aiConfig, setAiConfig] = useState({
    aiAnalysisThreshold: 80
  });

  const [MCQConfig, setMCQConfig] = useState({
    threshold: 80,
    timeLimitPerQuestion: 30,
    numQuestions: 15,
    difficultyDistribution: {
      easy: 5,
      medium: 5,
      hard: 5
    }
  });

  const [APTMCQConfig, setAPTMCQConfig] = useState({
    ...defaultAptitudeConfig
  });

  const [personalityConfig, setPersonalityConfig] = useState({
    subTests: []
  });

  const [errors, setErrors] = useState({
    aiAnalysisThreshold: '',
    aiAnalysisThresholdFormat: '',

    MCQThreshold: '',
    MCQThresholdFormat: '',
    MCQTimeLimit: '',
    MCQTimeLimitMin: '',
    MCQTimeLimitMax: '',
    MCQTimeLimitFormat: '',

    MCQNumQuestions: '',
    MCQNumQuestionsMin: '',
    MCQNumQuestionsMax: '',
    MCQNumQuestionsFormat: '',

    // Aptitude MCQ

    APTMCQThreshold: '',
    APTMCQThresholdFormat: '',
    APTMCQTimeLimit: '',
    APTMCQTimeLimitMin: '',
    APTMCQTimeLimitMax: '',
    APTMCQTimeLimitFormat: '',
    APTMCQNumQuestions: '',
    APTMCQNumQuestionsMin: '',
    APTMCQNumQuestionsMax: '',
    APTMCQNumQuestionsFormat: '',

    // Quantitative Aptitude

    quantAptNumQuestions: '',
    quantAptNumQuestionsMin: '',
    quantAptNumQuestionsMax: '',
    quantAptNumQuestionsFormat: '',

    quantAptEasyDifficulty: '',
    quantAptEasyDifficultyFormat: '',
    quantAptMediumDifficulty: '',
    quantAptMediumDifficultyFormat: '',
    quantAptHardDifficulty: '',
    quantAptHardDifficultyFormat: '',

    // Logical Reasoning

    logicalReasoningNumQuestions: '',
    logicalReasoningNumQuestionsMin: '',
    logicalReasoningNumQuestionsMax: '',
    logicalReasoningNumQuestionsFormat: '',

    logicalReasoningEasyDifficulty: '',
    logicalReasoningEasyDifficultyFormat: '',
    logicalReasoningMediumDifficulty: '',
    logicalReasoningMediumDifficultyFormat: '',
    logicalReasoningHardDifficulty: '',
    logicalReasoningHardDifficultyFormat: '',

    // Verbal Ability

    verbalAptNumQuestions: '',
    verbalAptNumQuestionsMin: '',
    verbalAptNumQuestionsMax: '',
    verbalAptNumQuestionsFormat: '',

    verbalAptEasyDifficulty: '',
    verbalAptEasyDifficultyFormat: '',
    verbalAptMediumDifficulty: '',
    verbalAptMediumDifficultyFormat: '',
    verbalAptHardDifficulty: '',
    verbalAptHardDifficultyFormat: '',

    // Situational Judgement

    sitJudgeNumQuestions: '',
    sitJudgeNumQuestionsMin: '',
    sitJudgeNumQuestionsMax: '',
    sitJudgeNumQuestionsFormat: '',

    sitJudgeEasyDifficulty: '',
    sitJudgeEasyDifficultyFormat: '',
    sitJudgeMediumDifficulty: '',
    sitJudgeMediumDifficultyFormat: '',
    sitJudgeHardDifficulty: '',
    sitJudgeHardDifficultyFormat: '',

    easyDifficulty: '',
    easyDifficultyFormat: '',
    mediumDifficulty: '',
    mediumDifficultyFormat: '',
    hardDifficulty: '',
    hardDifficultyFormat: ''
  });

  const userDetails = JSON.parse(localStorage.getItem('userDetails'));
  const orgId = userDetails?.user?.organizationId;
  const { jobId } = useParams();

  useEffect(() => {
    const isError = mcqConfigVisible
      ? Object.values(errors).some((error) => error !== '') // Check all errors if MCQConfig is visible
      : errors.aiAnalysisThreshold !== '';

    console.log(isError);
    if (Object.values(errors).some((error) => error !== '')) {
      setHasError(true);
    } else {
      setHasError(false);
    }
  });

  useEffect(() => {
    setAiConfig(
      extractConfig('AI_ANALYSIS') || {
        aiAnalysisThreshold: 80
      }
    );

    const mcqConfig = checkIfItemInArray(testProcesses, 'SKILL_BASED_MCQ_TEST');
    const quantAptConfig = checkIfItemInArray(
      testProcesses,
      'APTITUDE_MCQ_TEST'
    );
    if (mcqConfig) {
      setMcqConfigVisible(mcqConfig.status === 'DISABLED' ? false : true);
    } else {
      setMcqConfigVisible(false);
    }

    if (quantAptConfig) {
      setAptMcqConfigVisible(
        quantAptConfig.status === 'DISABLED' ? false : true
      );
    } else {
      setAptMcqConfigVisible(false);
    }

    setMCQConfig(
      extractConfig('SKILL_BASED_MCQ_TEST') || {
        threshold: 80,
        timeLimitPerQuestion: 30,
        numQuestions: 15,
        difficultyDistribution: {
          easy: 5,
          medium: 5,
          hard: 5
        }
      }
    );

    setAPTMCQConfig(
      extractConfig('APTITUDE_MCQ_TEST') || {
        ...defaultAptitudeConfig
      }
    );

    setPersonalityConfig(
      extractConfig('PERSONALITY_TEST') || {
        subTests: []
      }
    );

    setPersonalityConfigVisible(
      checkIfItemPresent(testProcesses, 'PERSONALITY_TEST') ? true : false
    );
  }, [testProcesses]);

  const extractConfig = (type) => {
    const item = testProcesses?.find((entry) => entry.type === type);
    if (type === 'APTITUDE_MCQ_TEST' && item) {
      if (item?.config) {
        if (!aptMcqConfigVisible) {
          setAptMcqConfigVisible(true);
        }
        if (item?.config?.questionsConfig?.QuantitativeAptitude) {
          if (!quantAptVisible) {
            setQuantAptVisible(true);
          }
        }
        if (item?.config?.questionsConfig?.LogicalReasoning) {
          if (!logicalAptVisible) {
            setLogicalAptVisible(true);
          }
        }
        if (item?.config?.questionsConfig?.verbalAbility) {
          if (!verbalAptVisible) {
            setVerbalAptVisible(true);
          }
        }
        if (item?.config?.questionsConfig?.situationalJudgment) {
          if (!situationalJudgementVisible) {
            setSituationalJudgementVisible(true);
          }
        }
      }
    }
    return item ? item.config : null;
  };
  const extractid = (type) => {
    const item = testProcesses?.find((entry) => entry.type === type);
    return item ? item._id : null;
  };

  const checkIfItemPresent = (array, item) => {
    return array.some((obj) => obj.type === item);
  };

  const checkIfItemInArray = (array, item) => {
    return array.find((obj) => obj.type === item);
  };

  // Function to validate config objects
  const validateConfig = (items) => {
    for (let item of items) {
      const { type, config } = item;
      const errorPath = checkEmptyValues(config, '');

      if (errorPath) {
        toast.error(`Empty value found in ${type} config at ${errorPath}`);
        return true; // Return true if an empty value is found
      }
    }
    return false; // Return false if no empty values are found
  };

  // Recursive function to check for empty string values
  const checkEmptyValues = (obj, path) => {
    for (let key in obj) {
      const currentPath = path ? `${path}.${key}` : key; // Avoids 'config.' prefix

      if (typeof obj[key] === 'object' && obj[key] !== null) {
        // Recursively check nested objects
        const nestedErrorPath = checkEmptyValues(obj[key], currentPath);
        if (nestedErrorPath) {
          return nestedErrorPath;
        }
      } else if (obj[key] === '') {
        // Return the path where the empty string is found
        return currentPath;
      }
    }
    return null;
  };

  const updateTestSteps = async () => {
    const URL = `${process.env.REACT_APP_DEV_API}/v1/organization/${orgId}/job/${jobId}/test-steps`;
    const userDetails = JSON.parse(localStorage.getItem('userDetails'));
    const accessToken = userDetails?.session.accessToken || null;
    if (!accessToken) {
      throw new Error('Access token not found');
    }

    let MCQError = false;
    let APTError = false;

    if (mcqConfigVisible) {
      const { easy, medium, hard } = MCQConfig.difficultyDistribution;
      const sum = parseInt(easy) + parseInt(medium) + parseInt(hard);
      if (sum != MCQConfig.numQuestions) {
        MCQError = true;
      }
    }

    if (aptMcqConfigVisible && APTMCQConfig.questionsConfig) {
      const {
        QuantitativeAptitude,
        LogicalReasoning,
        verbalAbility,
        situationalJudgment
      } = APTMCQConfig.questionsConfig;

      const activeQuestionTypes = [];
      if (quantAptVisible) {
        activeQuestionTypes.push(QuantitativeAptitude);
      }
      if (logicalAptVisible) {
        activeQuestionTypes.push(LogicalReasoning);
      }
      if (verbalAptVisible) {
        activeQuestionTypes.push(verbalAbility);
      }
      if (situationalJudgementVisible) {
        activeQuestionTypes.push(situationalJudgment);
      }

      for (const obj of activeQuestionTypes) {
        const sumDifficulty =
          obj?.difficultyDistribution?.easy +
            obj?.difficultyDistribution?.medium +
            obj?.difficultyDistribution?.hard || 0;
        if (obj?.numQuestions !== sumDifficulty) {
          APTError = true;
        }
      }

      const totalQuestions = activeQuestionTypes.reduce((acc, curr) => {
        return acc + (curr?.numQuestions || 0);
      }, 0);

      if ((APTMCQConfig?.numQuestions || 0) !== totalQuestions) {
        toast.error(
          'Total number of questions must match sum of all question types in Aptitude MCQ'
        );
        return;
      }
    }

    if (APTError && MCQError) {
      toast.error(
        'Number of questions in Aptitude MCQ and Skill-Based MCQ does not match with difficulty distribution of questions'
      );
      return;
    } else {
      if (APTError) {
        toast.error(
          'Number of questions in Aptitude MCQ does not match with difficulty distribution of questions'
        );
        return;
      } else if (MCQError) {
        toast.error(
          'Number of questions in Skill-Based MCQ does not match with difficulty distribution of questions'
        );
        return;
      }
    }

    let testProcesses = [
      {
        type: 'AI_ANALYSIS',
        config: aiConfig,
        _id: extractid('AI_ANALYSIS')
      }
    ];

    if (mcqConfigVisible) {
      const testProcessObj = {
        type: 'SKILL_BASED_MCQ_TEST',
        config: MCQConfig,
        status: 'ACTIVE'
      };
      const testProcessId = extractid('SKILL_BASED_MCQ_TEST');
      if (testProcessId) {
        testProcessObj['_id'] = testProcessId;
      }

      testProcesses.push(testProcessObj);
    }

    if (aptMcqConfigVisible) {
      let updatedAPTMCQConfig = {
        questionsConfig: {}
      };
      if (quantAptVisible) {
        updatedAPTMCQConfig.questionsConfig.QuantitativeAptitude = {
          ...APTMCQConfig.questionsConfig.QuantitativeAptitude
        };
      }
      if (logicalAptVisible) {
        updatedAPTMCQConfig.questionsConfig.LogicalReasoning = {
          ...APTMCQConfig.questionsConfig.LogicalReasoning
        };
      }
      if (verbalAptVisible) {
        updatedAPTMCQConfig.questionsConfig.verbalAbility = {
          ...APTMCQConfig.questionsConfig.verbalAbility
        };
      }
      if (situationalJudgementVisible) {
        updatedAPTMCQConfig.questionsConfig.situationalJudgment = {
          ...APTMCQConfig.questionsConfig.situationalJudgment
        };
      }

      const aptitudeSubTestsLength = Object.keys(
        updatedAPTMCQConfig.questionsConfig
      ).length;

      if (aptitudeSubTestsLength === 0) {
        toast.error(
          'Please select at least one aptitude subtest before saving.'
        );
        return;
      }

      const testProcessObj = {
        type: 'APTITUDE_MCQ_TEST',
        config: {
          ...updatedAPTMCQConfig,
          threshold: APTMCQConfig.threshold,
          timeLimit: APTMCQConfig.timeLimit,
          numQuestions: APTMCQConfig.numQuestions
        },
        status: 'ACTIVE'
      };
      const testProcessId = extractid('APTITUDE_MCQ_TEST');
      if (testProcessId) {
        testProcessObj['_id'] = testProcessId;
      }
      testProcesses.push(testProcessObj);
    }

    if (personalityConfigVisible && personalityConfig.subTests.length) {
      if (extractid('PERSONALITY_TEST')) {
        testProcesses.push({
          type: 'PERSONALITY_TEST',
          config: personalityConfig,
          _id: extractid('PERSONALITY_TEST')
        });
      } else {
        testProcesses.push({
          type: 'PERSONALITY_TEST',
          config: personalityConfig
        });
      }
    }
    if (personalityConfigVisible && !personalityConfig.subTests.length) {
      toast.error(
        'Please select at least one test from the Personality Test options before saving.'
      );
      return;
    }
    // if (validateConfig(testProcesses)) return;

    // const hasError = mcqConfigVisible
    //   ? Object.values(errors).some((error) => error !== "") // Check all errors if MCQConfig is visible
    //   : errors.aiAnalysisThreshold !== ""; // Only check aiAnalysisThreshold if MCQConfig is not visible

    // const errorMessages = Object.values(errors).find((error) => error !== "");

    // If a non-empty error message is found, show a toast
    if (hasError) {
      toast.error('Please fill all the required fields.');
      return;
    }

    const requestBody = {
      testProcesses: testProcesses
    };

    setLoading(true);
    try {
      const response = await fetch(URL, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`
        },
        body: JSON.stringify(requestBody)
      });

      const data = await response.json();
      if (response.ok) {
        // Handle success (e.g., update state, show success message, etc.)
        setLoading(false);
        // Perform other state updates or actions
        toast.success('Job updated successfully');
        fetchJobDetails();
      } else {
        // Handle error response
        setLoading(false);
        if (data.message) {
          if (data.message.includes('SKILL_BASED_MCQ_TEST')) {
            const formattedMessage =
              'Cannot Update test as the candidate has already started this process.';
            toast.error(formattedMessage);
            setMcqConfigVisible(true);
          } else if (data.message.includes('PERSONALITY_TEST')) {
            const formattedMessage =
              'Cannot Update the Test as the candidate has already started this process.';
            toast.error(formattedMessage);
          } else {
            toast.error(data.message);
          }
        } else {
          toast.error('Error updating test steps');
        }
        fetchJobDetails();
      }
    } catch (error) {
      setLoading(false);
      toast.error('An error occurred while updating test steps.');
    }
  };

  const fetchJobDetails = async () => {
    setLoading(true);
    const URL = `${process.env.REACT_APP_DEV_API}/v1/organization/${orgId}/job/${jobId}`;
    try {
      const response = await fetch(URL);
      const data = await response.json();
      if (response.ok) {
        setLoading(false);
        setTestProcesses(data.data.testProcesses);
      } else {
        setLoading(false);
        toast.error(data.message);
      }
    } catch (error) {
      setLoading(false);
      navigate('/page-error-404');
      toast.error('An error occurred while fetching job details.');
    }
  };

  useEffect(() => {
    if (situationalJudgementVisible) {
      setAPTMCQConfig((prev) => {
        const newOBj = { ...prev };
        newOBj.questionsConfig.situationalJudgment =
          defaultAptitudeConfig.questionsConfig.situationalJudgment;
        return newOBj;
      });
    }
  }, [situationalJudgementVisible]);
  useEffect(() => {
    if (logicalAptVisible) {
      setAPTMCQConfig((prev) => {
        const newOBj = { ...prev };
        newOBj.questionsConfig.LogicalReasoning =
          defaultAptitudeConfig.questionsConfig.LogicalReasoning;
        return newOBj;
      });
    }
  }, [logicalAptVisible]);
  useEffect(() => {
    if (quantAptVisible) {
      setAPTMCQConfig((prev) => {
        const newOBj = { ...prev };
        newOBj.questionsConfig.QuantitativeAptitude =
          defaultAptitudeConfig.questionsConfig.QuantitativeAptitude;
        return newOBj;
      });
    }
  }, [quantAptVisible]);
  useEffect(() => {
    if (verbalAptVisible) {
      setAPTMCQConfig((prev) => {
        const newOBj = { ...prev };
        newOBj.questionsConfig.verbalAbility =
          defaultAptitudeConfig.questionsConfig.verbalAbility;
        return newOBj;
      });
    }
  }, [verbalAptVisible]);

  return (
    <>
      {/* <Container className="py-5"> */}
      {/* <h1 className="text-center mb-4">Advanced Job Configuration</h1> */}
      <Card className="p-4 shadow-sm">
        <Form.Group className="mb-4">
          <Form.Label>
            Default AI Analysis Threshold{' '}
            <span className="text-danger scale5 ms-2">*</span>
          </Form.Label>
          <Form.Control
            type="text"
            value={aiConfig?.aiAnalysisThreshold}
            onChange={(e) => {
              const inputValue = e.target.value;
              // Remove any non-digit characters
              const filteredValue = inputValue.replace(/[^0-9]/g, '');

              setAiConfig((prevConfig) => ({
                ...prevConfig,
                aiAnalysisThreshold: filteredValue === '' ? '' : filteredValue
              }));

              setErrors((prevErrors) => ({
                ...prevErrors,
                aiAnalysisThreshold:
                  filteredValue === ''
                    ? 'This is a required field'
                    : +filteredValue > 100
                      ? 'This value cannot exceed 100'
                      : +filteredValue < 50
                        ? 'This value cannot be lower than 50'
                        : '',
                aiAnalysisThresholdFormat: ''
              }));
            }}
            isInvalid={
              errors.aiAnalysisThreshold || errors.aiAnalysisThresholdFormat
            }
          />
          {errors.aiAnalysisThreshold && (
            <Form.Text className="text-danger">
              {errors.aiAnalysisThreshold}
            </Form.Text>
          )}
          {errors.aiAnalysisThresholdFormat && (
            <Form.Text className="text-danger">
              {errors.aiAnalysisThresholdFormat}
            </Form.Text>
          )}
          <Form.Text className="text-muted">
            This threshold determines how closely a candidate's resume and
            application details match the job requirements. The default is 80%,
            meaning candidates must meet at least 80% of the criteria. The
            minimum allowed is 50%.
          </Form.Text>
        </Form.Group>

        {/* MCQ Pre-Assessment Test */}
        <Form.Group className="mb-4">
          <Form.Check
            type="checkbox"
            label="Skill-Based MCQ Assessment"
            onChange={(e) => setMcqConfigVisible(e.target.checked)}
            checked={mcqConfigVisible}
          />
          {mcqConfigVisible && (
            <div className="mt-3 ms-3 p-3  bg-sub-section  rounded shadow-sm">
              <Form.Group className="mb-3">
                <Form.Label>
                  Passing Threshold (%)
                  <span className="text-danger scale5 ms-2">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  value={MCQConfig?.threshold}
                  onChange={(e) => {
                    const inputValue = e.target.value;

                    // Remove any non-digit characters
                    const filteredValue = inputValue.replace(/[^0-9]/g, '');

                    setMCQConfig((prevConfig) => ({
                      ...prevConfig,
                      threshold: filteredValue === '' ? '' : filteredValue
                    }));

                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      MCQThreshold:
                        filteredValue === ''
                          ? 'This is a required field'
                          : +filteredValue > 100
                            ? 'This value cannot exceed 100'
                            : +filteredValue < 50
                              ? 'This value cannot be lower than 50'
                              : '',
                      MCQThresholdFormat: ''
                    }));
                  }}
                  isInvalid={errors.MCQThreshold || errors.MCQThresholdFormat}
                />
                {errors.MCQThreshold && (
                  <Form.Text className="text-danger">
                    {errors.MCQThreshold}
                  </Form.Text>
                )}
                {errors.MCQThresholdFormat && (
                  <Form.Text className="text-danger">
                    {errors.MCQThresholdFormat}
                  </Form.Text>
                )}
                <Form.Text className="text-muted">
                  Candidates must achieve this percentage or higher to pass the
                  Skill-Based MCQ Assessment. The default is set to 80%.
                </Form.Text>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>
                  Time Limit Per Question (seconds)
                  <span className="text-danger scale5 ms-2">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  value={MCQConfig?.timeLimitPerQuestion}
                  onChange={(e) => {
                    const inputValue = e.target.value;

                    // Remove any non-digit characters
                    const filteredValue = inputValue.replace(/[^0-9]/g, '');

                    setMCQConfig((prevConfig) => ({
                      ...prevConfig,
                      timeLimitPerQuestion:
                        filteredValue === '' ? '' : filteredValue
                    }));
                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      MCQTimeLimit:
                        filteredValue === ''
                          ? 'This is a required field'
                          : +filteredValue > 120
                            ? 'This value cannot exceed 120'
                            : +filteredValue < 15
                              ? 'This value cannot be lower than 15'
                              : '',
                      MCQTimeLimitFormat: ''
                    }));
                  }}
                  isInvalid={errors.MCQTimeLimit || errors.MCQTimeLimitFormat}
                />
                {errors.MCQTimeLimit && (
                  <Form.Text className="text-danger">
                    {errors.MCQTimeLimit}
                  </Form.Text>
                )}
                {errors.MCQTimeLimitFormat && (
                  <Form.Text className="text-danger">
                    {errors.MCQTimeLimitFormat}
                  </Form.Text>
                )}
                <Form.Text className="text-muted">
                  This is the time allocated for each question in the
                  Skill-Based MCQ Assessment. The default is 30 seconds per
                  question.
                </Form.Text>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>
                  Number of Questions
                  <span className="text-danger scale5 ms-2">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  value={MCQConfig?.numQuestions}
                  onChange={(e) => {
                    const inputValue = e.target.value;

                    // Remove any non-digit characters
                    const filteredValue = inputValue.replace(/[^0-9]/g, '');

                    setMCQConfig((prevConfig) => ({
                      ...prevConfig,
                      numQuestions: filteredValue === '' ? '' : filteredValue
                    }));

                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      MCQNumQuestions:
                        filteredValue === ''
                          ? 'This is a required field'
                          : +filteredValue > 30
                            ? 'This value cannot exceed 30'
                            : +filteredValue < 6
                              ? 'This value cannot be lower than 6'
                              : '',
                      MCQNumQuestionsFormat: ''
                    }));
                  }}
                  isInvalid={
                    errors.MCQNumQuestions || errors.MCQNumQuestionsFormat
                  }
                />
                {errors.MCQNumQuestions && (
                  <Form.Text className="text-danger">
                    {errors.MCQNumQuestions}
                  </Form.Text>
                )}
                {errors.MCQNumQuestionsFormat && (
                  <Form.Text className="text-danger">
                    {errors.MCQNumQuestionsFormat}
                  </Form.Text>
                )}
                <Form.Text className="text-muted">
                  Specify the total number of questions in the Skill-Based MCQ
                  Assessment.
                </Form.Text>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Difficulty Distribution</Form.Label>
                <Row>
                  {/* Easy Difficulty */}
                  <Col xs={12} sm={4}>
                    <Form.Label>
                      Easy<span className="text-danger scale5 ms-2">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Easy"
                      value={MCQConfig?.difficultyDistribution?.easy}
                      onChange={(e) => {
                        const inputValue = e.target.value;

                        // Remove any non-digit characters
                        const filteredValue = inputValue.replace(/[^0-9]/g, '');

                        setMCQConfig((prevConfig) => ({
                          ...prevConfig,
                          difficultyDistribution: {
                            ...prevConfig.difficultyDistribution,
                            easy: filteredValue === '' ? '' : filteredValue
                          }
                        }));

                        setErrors((prevErrors) => ({
                          ...prevErrors,
                          easyDifficulty:
                            filteredValue === ''
                              ? 'This is a required field'
                              : '',
                          easyDifficultyFormat: ''
                        }));
                      }}
                      isInvalid={
                        errors.easyDifficulty || errors.easyDifficultyFormat
                      }
                    />
                    {errors.easyDifficulty && (
                      <Form.Text className="text-danger">
                        {errors.easyDifficulty}
                      </Form.Text>
                    )}
                    {errors.easyDifficultyFormat && (
                      <Form.Text className="text-danger">
                        {errors.easyDifficultyFormat}
                      </Form.Text>
                    )}
                  </Col>

                  {/* Medium Difficulty */}
                  <Col xs={12} sm={4}>
                    <Form.Label>
                      Medium<span className="text-danger scale5 ms-2">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Medium"
                      className="mt-2 mt-sm-0"
                      value={MCQConfig?.difficultyDistribution?.medium}
                      onChange={(e) => {
                        const inputValue = e.target.value;

                        // Remove any non-digit characters
                        const filteredValue = inputValue.replace(/[^0-9]/g, '');

                        setMCQConfig((prevConfig) => ({
                          ...prevConfig,
                          difficultyDistribution: {
                            ...prevConfig.difficultyDistribution,
                            medium: filteredValue === '' ? '' : filteredValue
                          }
                        }));

                        setErrors((prevErrors) => ({
                          ...prevErrors,
                          mediumDifficulty:
                            filteredValue === ''
                              ? 'This is a required field'
                              : '',
                          mediumDifficultyFormat: ''
                        }));
                      }}
                      isInvalid={
                        errors.mediumDifficulty || errors.mediumDifficultyFormat
                      }
                    />
                    {errors.mediumDifficulty && (
                      <Form.Text className="text-danger">
                        {errors.mediumDifficulty}
                      </Form.Text>
                    )}
                    {errors.mediumDifficultyFormat && (
                      <Form.Text className="text-danger">
                        {errors.mediumDifficultyFormat}
                      </Form.Text>
                    )}
                  </Col>

                  {/* Hard Difficulty */}
                  <Col xs={12} sm={4}>
                    <Form.Label>
                      Hard<span className="text-danger scale5 ms-2">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Hard"
                      className="mt-2 mt-sm-0"
                      value={MCQConfig?.difficultyDistribution?.hard}
                      onChange={(e) => {
                        const inputValue = e.target.value;

                        // Remove any non-digit characters
                        const filteredValue = inputValue.replace(/[^0-9]/g, '');

                        setMCQConfig((prevConfig) => ({
                          ...prevConfig,
                          difficultyDistribution: {
                            ...prevConfig.difficultyDistribution,
                            hard: filteredValue === '' ? '' : filteredValue
                          }
                        }));

                        setErrors((prevErrors) => ({
                          ...prevErrors,
                          hardDifficulty:
                            filteredValue === ''
                              ? 'This is a required field'
                              : '',
                          hardDifficultyFormat: ''
                        }));
                      }}
                      isInvalid={
                        errors.hardDifficulty || errors.hardDifficultyFormat
                      }
                    />
                    {errors.hardDifficulty && (
                      <Form.Text className="text-danger">
                        {errors.hardDifficulty}
                      </Form.Text>
                    )}
                    {errors.hardDifficultyFormat && (
                      <Form.Text className="text-danger">
                        {errors.hardDifficultyFormat}
                      </Form.Text>
                    )}
                  </Col>
                </Row>
                <Form.Text className="text-muted mt-2">
                  Distribute the difficulty levels among the questions.
                </Form.Text>
              </Form.Group>
            </div>
          )}
        </Form.Group>

        {/* Personality Test */}
        <Form.Group className="mb-4">
          <Form.Check
            type="checkbox"
            label="Personality Test"
            onChange={(e) => setPersonalityConfigVisible(e.target.checked)}
            checked={personalityConfigVisible}
          />
          {personalityConfigVisible && (
            <div className="mt-3 ms-3 p-3  bg-sub-section  rounded shadow-sm">
              <Form.Check
                type="checkbox"
                label="DISC"
                className="mb-2"
                checked={checkIfItemPresent(personalityConfig.subTests, 'DISC')}
                onChange={(e) => {
                  setPersonalityConfig((prevConfig) => ({
                    ...prevConfig,
                    subTests: e.target.checked
                      ? [...prevConfig.subTests, { type: 'DISC' }]
                      : prevConfig.subTests.filter((obj) => obj.type !== 'DISC')
                  }));
                }}
              />
              <Form.Text className="text-muted ms-4">
                DISC assessment helps in understanding a candidate's behavioral
                style, which is crucial for determining fit for specific roles.
              </Form.Text>
              <Form.Check
                type="checkbox"
                label="Motivators"
                className="mb-2 mt-3"
                onChange={(e) => {
                  setPersonalityConfig((prevConfig) => ({
                    ...prevConfig,
                    subTests: e.target.checked
                      ? [...prevConfig.subTests, { type: 'MOTIVATORS' }]
                      : prevConfig.subTests.filter(
                          (obj) => obj.type !== 'MOTIVATORS'
                        )
                  }));
                }}
                checked={checkIfItemPresent(
                  personalityConfig.subTests,
                  'MOTIVATORS'
                )}
              />
              <Form.Text className="text-muted ms-4">
                Motivators test identifies what drives a candidate, helping to
                match them with the right environment and role within the
                company.
              </Form.Text>
              <Form.Check
                type="checkbox"
                label="HVP Plus"
                className="mb-2 mt-3"
                checked={checkIfItemPresent(
                  personalityConfig.subTests,
                  'HVP_PLUS'
                )}
                onChange={(e) => {
                  setPersonalityConfig((prevConfig) => ({
                    ...prevConfig,
                    subTests: e.target.checked
                      ? [...prevConfig.subTests, { type: 'HVP_PLUS' }]
                      : prevConfig.subTests.filter(
                          (obj) => obj.type !== 'HVP_PLUS'
                        )
                  }));
                }}
              />
              <Form.Text className="text-muted ms-4">
                HVP Plus assesses a candidate's values and how they align with
                the company’s core values, essential for long-term cultural fit.
              </Form.Text>
            </div>
          )}
        </Form.Group>

        {/* Aptitude MCQ */}
        <Form.Group className="mb-4">
          <Form.Check
            type="checkbox"
            label="Aptitude MCQ"
            onChange={(e) => setAptMcqConfigVisible(e.target.checked)}
            checked={aptMcqConfigVisible}
          />
          {aptMcqConfigVisible && (
            <div className="mt-3 ms-3 p-3  bg-sub-section  rounded shadow-sm">
              {/* Passing Threshold */}
              <Form.Group className="mb-4">
                <Form.Label>
                  Passing Threshold (%)
                  <span className="text-danger scale5 ms-2">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder={'Enter passing threshold percentage'}
                  className="mt-2 mt-sm-0"
                  value={APTMCQConfig?.threshold}
                  onChange={(e) => {
                    const inputValue = e.target.value;

                    // Remove any non-digit characters
                    const filteredValue = inputValue.replace(/[^0-9]/g, '');

                    setAPTMCQConfig((prevConfig) => ({
                      ...prevConfig,
                      threshold:
                        filteredValue === '' ? '' : parseInt(filteredValue)
                    }));

                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      APTMCQThreshold:
                        filteredValue === ''
                          ? 'This is a required field'
                          : +filteredValue > 100
                            ? 'This value cannot exceed 100'
                            : +filteredValue < 50
                              ? 'This value cannot be lower than 50'
                              : '',
                      APTMCQThresholdFormat: ''
                    }));
                  }}
                  isInvalid={
                    errors.APTMCQThreshold || errors.APTMCQThresholdFormat
                  }
                />
                {errors.APTMCQThreshold && (
                  <Form.Text className="text-danger">
                    {errors.APTMCQThreshold}
                  </Form.Text>
                )}
                {errors.APTMCQThresholdFormat && (
                  <Form.Text className="text-danger">
                    {errors.APTMCQThresholdFormat}
                  </Form.Text>
                )}
              </Form.Group>
              {/* Time Limit */}
              <Form.Group className="mb-4">
                <Form.Label>
                  Time Limit Per Question (seconds)
                  <span className="text-danger scale5 ms-2">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder={'Enter time limit Per Question in seconds'}
                  className="mt-2 mt-sm-0"
                  value={APTMCQConfig?.timeLimit}
                  onChange={(e) => {
                    const inputValue = e.target.value;

                    // Remove any non-digit characters
                    const filteredValue = inputValue.replace(/[^0-9]/g, '');

                    setAPTMCQConfig((prevConfig) => ({
                      ...prevConfig,
                      timeLimit:
                        filteredValue === '' ? '' : parseInt(filteredValue)
                    }));

                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      APTMCQTimeLimit:
                        filteredValue === ''
                          ? 'This is a required field'
                          : +filteredValue > 120
                            ? 'This value cannot exceed 120'
                            : +filteredValue < 15
                              ? 'This value cannot be lower than 15'
                              : '',
                      APTMCQTimeLimitFormat: ''
                    }));
                  }}
                  isInvalid={
                    errors.APTMCQTimeLimit || errors.APTMCQTimeLimitFormat
                  }
                />
                {errors.APTMCQTimeLimit && (
                  <Form.Text className="text-danger">
                    {errors.APTMCQTimeLimit}
                  </Form.Text>
                )}
                {errors.APTMCQTimeLimitFormat && (
                  <Form.Text className="text-danger">
                    {errors.APTMCQTimeLimitFormat}
                  </Form.Text>
                )}
              </Form.Group>
              {/* Total Number of Questions */}
              <Form.Group className="mb-4">
                <Form.Label>
                  Total Number of Questions
                  <span className="text-danger scale5 ms-2">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter total number of questions"
                  className="mt-2 mt-sm-0"
                  value={APTMCQConfig?.numQuestions}
                  onChange={(e) => {
                    const inputValue = e.target.value;

                    // Remove any non-digit characters
                    const filteredValue = inputValue.replace(/[^0-9]/g, '');

                    setAPTMCQConfig((prevConfig) => ({
                      ...prevConfig,
                      numQuestions:
                        filteredValue === '' ? '' : parseInt(filteredValue)
                    }));

                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      APTMCQNumQuestions:
                        filteredValue === ''
                          ? 'This is a required field'
                          : +filteredValue > 30
                            ? 'This value cannot exceed 30'
                            : +filteredValue < 6
                              ? 'This value cannot be lower than 6'
                              : '',
                      APTMCQNumQuestionsFormat: ''
                    }));
                  }}
                  isInvalid={
                    errors.APTMCQNumQuestions || errors.APTMCQNumQuestionsFormat
                  }
                />
                {errors.APTMCQNumQuestions && (
                  <Form.Text className="text-danger">
                    {errors.APTMCQNumQuestions}
                  </Form.Text>
                )}
                {errors.APTMCQNumQuestionsFormat && (
                  <Form.Text className="text-danger">
                    {errors.APTMCQNumQuestionsFormat}
                  </Form.Text>
                )}
              </Form.Group>

              {/* Quantitative Aptitude Test */}
              <Form.Group className="mb-4">
                <Form.Check
                  type="checkbox"
                  label="Quantitative Aptitude"
                  onChange={(e) => setQuantAptVisible(e.target.checked)}
                  checked={quantAptVisible}
                />
                {quantAptVisible && (
                  <div className="mt-3 ms-3 p-3  bg-sub-section  rounded shadow-sm">
                    <Form.Group className="mb-3">
                      <Form.Label>
                        Total Number Of Questions
                        <span className="text-danger scale5 ms-2">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        value={
                          APTMCQConfig.questionsConfig.QuantitativeAptitude
                            ?.numQuestions
                        }
                        onChange={(e) => {
                          const inputValue = e.target.value;

                          // Remove any non-digit characters
                          const filteredValue = inputValue.replace(
                            /[^0-9]/g,
                            ''
                          );

                          setAPTMCQConfig({
                            ...APTMCQConfig,
                            questionsConfig: {
                              ...APTMCQConfig.questionsConfig,
                              QuantitativeAptitude: {
                                ...APTMCQConfig.questionsConfig[
                                  'QuantitativeAptitude'
                                ],
                                numQuestions:
                                  filteredValue === ''
                                    ? ''
                                    : parseInt(filteredValue)
                              }
                            }
                          });

                          setErrors((prevErrors) => ({
                            ...prevErrors,
                            quantAptNumQuestions:
                              filteredValue === ''
                                ? 'This is a required field'
                                : +filteredValue > 30
                                  ? 'This value cannot exceed 30'
                                  : +filteredValue < 6
                                    ? 'This value cannot be lower than 6'
                                    : '',
                            quantAptNumQuestionsFormat: ''
                          }));
                        }}
                        isInvalid={
                          errors.quantAptNumQuestions ||
                          errors.quantAptNumQuestionsFormat
                        }
                      />
                      {errors.quantAptNumQuestions && (
                        <Form.Text className="text-danger">
                          {errors.quantAptNumQuestions}
                        </Form.Text>
                      )}
                      {errors.quantAptNumQuestionsFormat && (
                        <Form.Text className="text-danger">
                          {errors.quantAptNumQuestionsFormat}
                        </Form.Text>
                      )}
                      <Form.Text className="text-muted">
                        Specify the total number of questions in the
                        Quantitative Aptitude Test.
                      </Form.Text>
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>Difficulty Distribution</Form.Label>
                      <Row>
                        {/* Easy Difficulty */}
                        <Col xs={12} sm={4}>
                          <Form.Label>
                            Easy
                            <span className="text-danger scale5 ms-2">*</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Easy"
                            value={
                              APTMCQConfig.questionsConfig.QuantitativeAptitude
                                ?.difficultyDistribution?.easy
                            }
                            onChange={(e) => {
                              const inputValue = e.target.value;

                              // Remove any non-digit characters
                              const filteredValue = inputValue.replace(
                                /[^0-9]/g,
                                ''
                              );

                              setAPTMCQConfig({
                                ...APTMCQConfig,
                                questionsConfig: {
                                  ...APTMCQConfig.questionsConfig,
                                  QuantitativeAptitude: {
                                    ...APTMCQConfig.questionsConfig[
                                      'QuantitativeAptitude'
                                    ],
                                    difficultyDistribution: {
                                      ...APTMCQConfig.questionsConfig[
                                        'QuantitativeAptitude'
                                      ]?.difficultyDistribution,
                                      easy:
                                        filteredValue === ''
                                          ? ''
                                          : parseInt(filteredValue)
                                    }
                                  }
                                }
                              });

                              setErrors((prevErrors) => ({
                                ...prevErrors,
                                quantAptEasyDifficulty:
                                  filteredValue === ''
                                    ? 'This is a required field'
                                    : +filteredValue > 20
                                      ? 'This value cannot exceed 20'
                                      : '',
                                quantAptEasyDifficultyFormat: ''
                              }));
                            }}
                            isInvalid={
                              errors.quantAptEasyDifficulty ||
                              errors.quantAptEasyDifficultyFormat
                            }
                          />
                          {errors.quantAptEasyDifficulty && (
                            <Form.Text className="text-danger">
                              {errors.quantAptEasyDifficulty}
                            </Form.Text>
                          )}
                          {errors.quantAptEasyDifficultyFormat && (
                            <Form.Text className="text-danger">
                              {errors.quantAptEasyDifficultyFormat}
                            </Form.Text>
                          )}
                        </Col>

                        {/* Medium Difficulty */}
                        <Col xs={12} sm={4}>
                          <Form.Label>
                            Medium
                            <span className="text-danger scale5 ms-2">*</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Medium"
                            className="mt-2 mt-sm-0"
                            value={
                              APTMCQConfig.questionsConfig.QuantitativeAptitude
                                ?.difficultyDistribution?.medium
                            }
                            onChange={(e) => {
                              const inputValue = e.target.value;

                              // Remove any non-digit characters
                              const filteredValue = inputValue.replace(
                                /[^0-9]/g,
                                ''
                              );

                              setAPTMCQConfig({
                                ...APTMCQConfig,
                                questionsConfig: {
                                  ...APTMCQConfig.questionsConfig,
                                  QuantitativeAptitude: {
                                    ...APTMCQConfig.questionsConfig[
                                      'QuantitativeAptitude'
                                    ],
                                    difficultyDistribution: {
                                      ...APTMCQConfig.questionsConfig[
                                        'QuantitativeAptitude'
                                      ]?.difficultyDistribution,
                                      medium:
                                        filteredValue === ''
                                          ? ''
                                          : parseInt(filteredValue)
                                    }
                                  }
                                }
                              });

                              setErrors((prevErrors) => ({
                                ...prevErrors,
                                quantAptMediumDifficulty:
                                  filteredValue === ''
                                    ? 'This is a required field'
                                    : +filteredValue > 20
                                      ? 'This value cannot exceed 20'
                                      : '',
                                quantAptMediumDifficultyFormat: ''
                              }));
                            }}
                            isInvalid={
                              errors.quantAptMediumDifficulty ||
                              errors.quantAptMediumDifficultyFormat
                            }
                          />
                          {errors.quantAptMediumDifficulty && (
                            <Form.Text className="text-danger">
                              {errors.quantAptMediumDifficulty}
                            </Form.Text>
                          )}
                          {errors.quantAptMediumDifficultyFormat && (
                            <Form.Text className="text-danger">
                              {errors.quantAptMediumDifficultyFormat}
                            </Form.Text>
                          )}
                        </Col>

                        {/* Hard Difficulty */}
                        <Col xs={12} sm={4}>
                          <Form.Label>
                            Hard
                            <span className="text-danger scale5 ms-2">*</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Hard"
                            className="mt-2 mt-sm-0"
                            value={
                              APTMCQConfig.questionsConfig.QuantitativeAptitude
                                ?.difficultyDistribution?.hard
                            }
                            onChange={(e) => {
                              const inputValue = e.target.value;

                              // Remove any non-digit characters
                              const filteredValue = inputValue.replace(
                                /[^0-9]/g,
                                ''
                              );

                              setAPTMCQConfig({
                                ...APTMCQConfig,
                                questionsConfig: {
                                  ...APTMCQConfig.questionsConfig,
                                  QuantitativeAptitude: {
                                    ...APTMCQConfig.questionsConfig[
                                      'QuantitativeAptitude'
                                    ],
                                    difficultyDistribution: {
                                      ...APTMCQConfig.questionsConfig[
                                        'QuantitativeAptitude'
                                      ]?.difficultyDistribution,
                                      hard:
                                        filteredValue === ''
                                          ? ''
                                          : parseInt(filteredValue)
                                    }
                                  }
                                }
                              });

                              setErrors((prevErrors) => ({
                                ...prevErrors,
                                quantAptHardDifficulty:
                                  filteredValue === ''
                                    ? 'This is a required field'
                                    : +filteredValue > 20
                                      ? 'This value cannot exceed 20'
                                      : '',
                                quantAptHardDifficultyFormat: ''
                              }));
                            }}
                            isInvalid={
                              errors.quantAptHardDifficulty ||
                              errors.quantAptHardDifficultyFormat
                            }
                          />
                          {errors.quantAptHardDifficulty && (
                            <Form.Text className="text-danger">
                              {errors.quantAptHardDifficulty}
                            </Form.Text>
                          )}
                          {errors.quantAptHardDifficultyFormat && (
                            <Form.Text className="text-danger">
                              {errors.quantAptHardDifficultyFormat}
                            </Form.Text>
                          )}
                        </Col>
                      </Row>
                      <Form.Text className="text-muted mt-2">
                        Distribute the difficulty levels among the questions.
                      </Form.Text>
                    </Form.Group>
                  </div>
                )}
              </Form.Group>

              {/* Logical Reasoning Test */}
              <Form.Group className="mb-4">
                <Form.Check
                  type="checkbox"
                  label="Logical Reasoning"
                  onChange={(e) => setLogicalAptVisible(e.target.checked)}
                  checked={logicalAptVisible}
                />
                {logicalAptVisible && (
                  <div className="mt-3 ms-3 p-3  bg-sub-section  rounded shadow-sm">
                    <Form.Group className="mb-3">
                      <Form.Label>
                        Total Number Of Questions
                        <span className="text-danger scale5 ms-2">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        value={
                          APTMCQConfig.questionsConfig.LogicalReasoning
                            ?.numQuestions
                        }
                        // value={logicalReasoningConfig?.numQuestions}
                        onChange={(e) => {
                          const inputValue = e.target.value;

                          // Remove any non-digit characters
                          const filteredValue = inputValue.replace(
                            /[^0-9]/g,
                            ''
                          );

                          setAPTMCQConfig({
                            ...APTMCQConfig,
                            questionsConfig: {
                              ...APTMCQConfig.questionsConfig,
                              LogicalReasoning: {
                                ...APTMCQConfig.questionsConfig[
                                  'LogicalReasoning'
                                ],
                                numQuestions:
                                  filteredValue === ''
                                    ? ''
                                    : parseInt(filteredValue)
                              }
                            }
                          });

                          setErrors((prevErrors) => ({
                            ...prevErrors,
                            logicalReasoningNumQuestions:
                              filteredValue === ''
                                ? 'This is a required field'
                                : +filteredValue > 30
                                  ? 'This value cannot exceed 30'
                                  : +filteredValue < 6
                                    ? 'This value cannot be lower than 6'
                                    : '',
                            logicalReasoningNumQuestionsFormat: ''
                          }));
                        }}
                        isInvalid={
                          errors.logicalReasoningNumQuestions ||
                          errors.logicalReasoningNumQuestionsFormat
                        }
                      />
                      {errors.logicalReasoningNumQuestions && (
                        <Form.Text className="text-danger">
                          {errors.logicalReasoningNumQuestions}
                        </Form.Text>
                      )}
                      {errors.logicalReasoningNumQuestionsFormat && (
                        <Form.Text className="text-danger">
                          {errors.logicalReasoningNumQuestionsFormat}
                        </Form.Text>
                      )}
                      <Form.Text className="text-muted">
                        Specify the total number of questions in the Logical
                        Reasoning Test.
                      </Form.Text>
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>Difficulty Distribution</Form.Label>
                      <Row>
                        {/* Easy Difficulty */}
                        <Col xs={12} sm={4}>
                          <Form.Label>
                            Easy
                            <span className="text-danger scale5 ms-2">*</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Easy"
                            value={
                              APTMCQConfig.questionsConfig.LogicalReasoning
                                ?.difficultyDistribution?.easy
                            }
                            onChange={(e) => {
                              const inputValue = e.target.value;

                              // Remove any non-digit characters
                              const filteredValue = inputValue.replace(
                                /[^0-9]/g,
                                ''
                              );

                              setAPTMCQConfig({
                                ...APTMCQConfig,
                                questionsConfig: {
                                  ...APTMCQConfig.questionsConfig,
                                  LogicalReasoning: {
                                    ...APTMCQConfig.questionsConfig[
                                      'LogicalReasoning'
                                    ],
                                    difficultyDistribution: {
                                      ...APTMCQConfig.questionsConfig[
                                        'LogicalReasoning'
                                      ]?.difficultyDistribution,
                                      easy:
                                        filteredValue === ''
                                          ? ''
                                          : parseInt(filteredValue)
                                    }
                                  }
                                }
                              });

                              setErrors((prevErrors) => ({
                                ...prevErrors,
                                logicalReasoningEasyDifficulty:
                                  filteredValue === ''
                                    ? 'This is a required field'
                                    : +filteredValue > 20
                                      ? 'This value cannot exceed 20'
                                      : '',
                                logicalReasoningEasyDifficultyFormat: ''
                              }));
                            }}
                            isInvalid={
                              errors.logicalReasoningEasyDifficulty ||
                              errors.logicalReasoningEasyDifficultyFormat
                            }
                          />
                          {errors.logicalReasoningEasyDifficulty && (
                            <Form.Text className="text-danger">
                              {errors.logicalReasoningEasyDifficulty}
                            </Form.Text>
                          )}
                          {errors.logicalReasoningEasyDifficultyFormat && (
                            <Form.Text className="text-danger">
                              {errors.logicalReasoningEasyDifficultyFormat}
                            </Form.Text>
                          )}
                        </Col>

                        {/* Medium Difficulty */}
                        <Col xs={12} sm={4}>
                          <Form.Label>
                            Medium
                            <span className="text-danger scale5 ms-2">*</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Medium"
                            className="mt-2 mt-sm-0"
                            value={
                              APTMCQConfig.questionsConfig.LogicalReasoning
                                ?.difficultyDistribution?.medium
                            }
                            onChange={(e) => {
                              const inputValue = e.target.value;

                              // Remove any non-digit characters
                              const filteredValue = inputValue.replace(
                                /[^0-9]/g,
                                ''
                              );

                              setAPTMCQConfig({
                                ...APTMCQConfig,
                                questionsConfig: {
                                  ...APTMCQConfig.questionsConfig,
                                  LogicalReasoning: {
                                    ...APTMCQConfig.questionsConfig[
                                      'LogicalReasoning'
                                    ],
                                    difficultyDistribution: {
                                      ...APTMCQConfig.questionsConfig[
                                        'LogicalReasoning'
                                      ]?.difficultyDistribution,
                                      medium:
                                        filteredValue === ''
                                          ? ''
                                          : parseInt(filteredValue)
                                    }
                                  }
                                }
                              });

                              setErrors((prevErrors) => ({
                                ...prevErrors,
                                logicalReasoningMediumDifficulty:
                                  filteredValue === ''
                                    ? 'This is a required field'
                                    : +filteredValue > 20
                                      ? 'This value cannot exceed 20'
                                      : '',
                                logicalReasoningMediumDifficultyFormat: ''
                              }));
                            }}
                            isInvalid={
                              errors.logicalReasoningMediumDifficulty ||
                              errors.logicalReasoningMediumDifficultyFormat
                            }
                          />
                          {errors.logicalReasoningMediumDifficulty && (
                            <Form.Text className="text-danger">
                              {errors.logicalReasoningMediumDifficulty}
                            </Form.Text>
                          )}
                          {errors.logicalReasoningMediumDifficultyFormat && (
                            <Form.Text className="text-danger">
                              {errors.logicalReasoningMediumDifficultyFormat}
                            </Form.Text>
                          )}
                        </Col>

                        {/* Hard Difficulty */}
                        <Col xs={12} sm={4}>
                          <Form.Label>
                            Hard
                            <span className="text-danger scale5 ms-2">*</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Hard"
                            className="mt-2 mt-sm-0"
                            value={
                              APTMCQConfig.questionsConfig.LogicalReasoning
                                ?.difficultyDistribution?.hard
                            }
                            onChange={(e) => {
                              const inputValue = e.target.value;

                              // Remove any non-digit characters
                              const filteredValue = inputValue.replace(
                                /[^0-9]/g,
                                ''
                              );

                              setAPTMCQConfig({
                                ...APTMCQConfig,
                                questionsConfig: {
                                  ...APTMCQConfig.questionsConfig,
                                  LogicalReasoning: {
                                    ...APTMCQConfig.questionsConfig[
                                      'LogicalReasoning'
                                    ],
                                    difficultyDistribution: {
                                      ...APTMCQConfig.questionsConfig[
                                        'LogicalReasoning'
                                      ]?.difficultyDistribution,
                                      hard:
                                        filteredValue === ''
                                          ? ''
                                          : parseInt(filteredValue)
                                    }
                                  }
                                }
                              });

                              setErrors((prevErrors) => ({
                                ...prevErrors,
                                logicalReasoningHardDifficulty:
                                  filteredValue === ''
                                    ? 'This is a required field'
                                    : +filteredValue > 20
                                      ? 'This value cannot exceed 20'
                                      : '',
                                logicalReasoningHardDifficultyFormat: ''
                              }));
                            }}
                            isInvalid={
                              errors.logicalReasoningHardDifficulty ||
                              errors.logicalReasoningHardDifficultyFormat
                            }
                          />
                          {errors.logicalReasoningHardDifficulty && (
                            <Form.Text className="text-danger">
                              {errors.logicalReasoningHardDifficulty}
                            </Form.Text>
                          )}
                          {errors.logicalReasoningHardDifficultyFormat && (
                            <Form.Text className="text-danger">
                              {errors.logicalReasoningHardDifficultyFormat}
                            </Form.Text>
                          )}
                        </Col>
                      </Row>
                      <Form.Text className="text-muted mt-2">
                        Distribute the difficulty levels among the questions.
                      </Form.Text>
                    </Form.Group>
                  </div>
                )}
              </Form.Group>

              {/* Verbal Ability Test */}
              <Form.Group className="mb-4">
                <Form.Check
                  type="checkbox"
                  label="Verbal Ability"
                  onChange={(e) => setVerbalAptVisible(e.target.checked)}
                  checked={verbalAptVisible}
                />
                {verbalAptVisible && (
                  <div className="mt-3 ms-3 p-3  bg-sub-section  rounded shadow-sm">
                    <Form.Group className="mb-3">
                      <Form.Label>
                        Total Number Of Questions
                        <span className="text-danger scale5 ms-2">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        value={
                          APTMCQConfig.questionsConfig.verbalAbility
                            ?.numQuestions
                        }
                        onChange={(e) => {
                          const inputValue = e.target.value;

                          // Remove any non-digit characters
                          const filteredValue = inputValue.replace(
                            /[^0-9]/g,
                            ''
                          );

                          setAPTMCQConfig({
                            ...APTMCQConfig,
                            questionsConfig: {
                              ...APTMCQConfig.questionsConfig,
                              verbalAbility: {
                                ...APTMCQConfig.questionsConfig[
                                  'verbalAbility'
                                ],
                                numQuestions:
                                  filteredValue === ''
                                    ? ''
                                    : parseInt(filteredValue)
                              }
                            }
                          });

                          setErrors((prevErrors) => ({
                            ...prevErrors,
                            verbalAptNumQuestions:
                              filteredValue === ''
                                ? 'This is a required field'
                                : +filteredValue > 30
                                  ? 'This value cannot exceed 30'
                                  : +filteredValue < 6
                                    ? 'This value cannot be lower than 6'
                                    : '',
                            verbalAptNumQuestionsFormat: ''
                          }));
                        }}
                        isInvalid={
                          errors.verbalAptNumQuestions ||
                          errors.verbalAptNumQuestionsFormat
                        }
                      />
                      {errors.verbalAptNumQuestions && (
                        <Form.Text className="text-danger">
                          {errors.verbalAptNumQuestions}
                        </Form.Text>
                      )}
                      {errors.verbalAptNumQuestionsFormat && (
                        <Form.Text className="text-danger">
                          {errors.verbalAptNumQuestionsFormat}
                        </Form.Text>
                      )}
                      <Form.Text className="text-muted">
                        Specify the total number of questions in the Verbal
                        Ability Test.
                      </Form.Text>
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>Difficulty Distribution</Form.Label>
                      <Row>
                        {/* Easy Difficulty */}
                        <Col xs={12} sm={4}>
                          <Form.Label>
                            Easy
                            <span className="text-danger scale5 ms-2">*</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Easy"
                            value={
                              APTMCQConfig.questionsConfig.verbalAbility
                                ?.difficultyDistribution?.easy
                            }
                            onChange={(e) => {
                              const inputValue = e.target.value;

                              // Remove any non-digit characters
                              const filteredValue = inputValue.replace(
                                /[^0-9]/g,
                                ''
                              );

                              setAPTMCQConfig({
                                ...APTMCQConfig,
                                questionsConfig: {
                                  ...APTMCQConfig.questionsConfig,
                                  verbalAbility: {
                                    ...APTMCQConfig.questionsConfig[
                                      'verbalAbility'
                                    ],
                                    difficultyDistribution: {
                                      ...APTMCQConfig.questionsConfig[
                                        'verbalAbility'
                                      ]?.difficultyDistribution,
                                      easy:
                                        filteredValue === ''
                                          ? ''
                                          : parseInt(filteredValue)
                                    }
                                  }
                                }
                              });

                              setErrors((prevErrors) => ({
                                ...prevErrors,
                                verbalAptEasyDifficulty:
                                  filteredValue === ''
                                    ? 'This is a required field'
                                    : +filteredValue > 20
                                      ? 'This value cannot exceed 20'
                                      : '',
                                verbalAptEasyDifficultyFormat: ''
                              }));
                            }}
                            isInvalid={
                              errors.verbalAptEasyDifficulty ||
                              errors.verbalAptEasyDifficultyFormat
                            }
                          />
                          {errors.verbalAptEasyDifficulty && (
                            <Form.Text className="text-danger">
                              {errors.verbalAptEasyDifficulty}
                            </Form.Text>
                          )}
                          {errors.verbalAptEasyDifficultyFormat && (
                            <Form.Text className="text-danger">
                              {errors.verbalAptEasyDifficultyFormat}
                            </Form.Text>
                          )}
                        </Col>

                        {/* Medium Difficulty */}
                        <Col xs={12} sm={4}>
                          <Form.Label>
                            Medium
                            <span className="text-danger scale5 ms-2">*</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Medium"
                            className="mt-2 mt-sm-0"
                            value={
                              APTMCQConfig.questionsConfig.verbalAbility
                                ?.difficultyDistribution?.medium
                            }
                            onChange={(e) => {
                              const inputValue = e.target.value;

                              // Remove any non-digit characters
                              const filteredValue = inputValue.replace(
                                /[^0-9]/g,
                                ''
                              );

                              setAPTMCQConfig({
                                ...APTMCQConfig,
                                questionsConfig: {
                                  ...APTMCQConfig.questionsConfig,
                                  verbalAbility: {
                                    ...APTMCQConfig.questionsConfig[
                                      'verbalAbility'
                                    ],
                                    difficultyDistribution: {
                                      ...APTMCQConfig.questionsConfig[
                                        'verbalAbility'
                                      ]?.difficultyDistribution,
                                      medium:
                                        filteredValue === ''
                                          ? ''
                                          : parseInt(filteredValue)
                                    }
                                  }
                                }
                              });

                              setErrors((prevErrors) => ({
                                ...prevErrors,
                                verbalAptMediumDifficulty:
                                  filteredValue === ''
                                    ? 'This is a required field'
                                    : +filteredValue > 20
                                      ? 'This value cannot exceed 20'
                                      : '',
                                verbalAptMediumDifficultyFormat: ''
                              }));
                            }}
                            isInvalid={
                              errors.verbalAptMediumDifficulty ||
                              errors.verbalAptMediumDifficultyFormat
                            }
                          />
                          {errors.verbalAptMediumDifficulty && (
                            <Form.Text className="text-danger">
                              {errors.verbalAptMediumDifficulty}
                            </Form.Text>
                          )}
                          {errors.verbalAptMediumDifficultyFormat && (
                            <Form.Text className="text-danger">
                              {errors.verbalAptMediumDifficultyFormat}
                            </Form.Text>
                          )}
                        </Col>

                        {/* Hard Difficulty */}
                        <Col xs={12} sm={4}>
                          <Form.Label>
                            Hard
                            <span className="text-danger scale5 ms-2">*</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Hard"
                            className="mt-2 mt-sm-0"
                            value={
                              APTMCQConfig.questionsConfig.verbalAbility
                                ?.difficultyDistribution?.hard
                            }
                            onChange={(e) => {
                              const inputValue = e.target.value;

                              // Remove any non-digit characters
                              const filteredValue = inputValue.replace(
                                /[^0-9]/g,
                                ''
                              );

                              setAPTMCQConfig({
                                ...APTMCQConfig,
                                questionsConfig: {
                                  ...APTMCQConfig.questionsConfig,
                                  verbalAbility: {
                                    ...APTMCQConfig.questionsConfig[
                                      'verbalAbility'
                                    ],
                                    difficultyDistribution: {
                                      ...APTMCQConfig.questionsConfig[
                                        'verbalAbility'
                                      ]?.difficultyDistribution,
                                      hard:
                                        filteredValue === ''
                                          ? ''
                                          : parseInt(filteredValue)
                                    }
                                  }
                                }
                              });

                              setErrors((prevErrors) => ({
                                ...prevErrors,
                                verbalAptHardDifficulty:
                                  filteredValue === ''
                                    ? 'This is a required field'
                                    : +filteredValue > 20
                                      ? 'This value cannot exceed 20'
                                      : '',
                                verbalAptHardDifficultyFormat: ''
                              }));
                            }}
                            isInvalid={
                              errors.verbalAptHardDifficulty ||
                              errors.verbalAptHardDifficultyFormat
                            }
                          />
                          {errors.verbalAptHardDifficulty && (
                            <Form.Text className="text-danger">
                              {errors.verbalAptHardDifficulty}
                            </Form.Text>
                          )}
                          {errors.verbalAptHardDifficultyFormat && (
                            <Form.Text className="text-danger">
                              {errors.verbalAptHardDifficultyFormat}
                            </Form.Text>
                          )}
                        </Col>
                      </Row>
                      <Form.Text className="text-muted mt-2">
                        Distribute the difficulty levels among the questions.
                      </Form.Text>
                    </Form.Group>
                  </div>
                )}
              </Form.Group>

              {/* Situational Judgement Test */}
              <Form.Group className="mb-4">
                <Form.Check
                  type="checkbox"
                  label="Situational Judgement"
                  onChange={(e) =>
                    setSituationalJudgementVisible(e.target.checked)
                  }
                  checked={situationalJudgementVisible}
                />
                {situationalJudgementVisible && (
                  <div className="mt-3 ms-3 p-3  bg-sub-section  rounded shadow-sm">
                    <Form.Group className="mb-3">
                      <Form.Label>
                        Total Number Of Questions
                        <span className="text-danger scale5 ms-2">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        value={
                          APTMCQConfig.questionsConfig.situationalJudgment
                            ?.numQuestions
                        }
                        onChange={(e) => {
                          const inputValue = e.target.value;

                          // Remove any non-digit characters
                          const filteredValue = inputValue.replace(
                            /[^0-9]/g,
                            ''
                          );

                          setAPTMCQConfig({
                            ...APTMCQConfig,
                            questionsConfig: {
                              ...APTMCQConfig.questionsConfig,
                              situationalJudgment: {
                                ...APTMCQConfig.questionsConfig[
                                  'situationalJudgment'
                                ],
                                numQuestions:
                                  filteredValue === ''
                                    ? ''
                                    : parseInt(filteredValue)
                              }
                            }
                          });

                          setErrors((prevErrors) => ({
                            ...prevErrors,
                            sitJudgeNumQuestions:
                              filteredValue === ''
                                ? 'This is a required field'
                                : +filteredValue > 30
                                  ? 'This value cannot exceed 30'
                                  : +filteredValue < 6
                                    ? 'This value cannot be lower than 6'
                                    : '',
                            sitJudgeNumQuestionsFormat: ''
                          }));
                        }}
                        isInvalid={
                          errors.sitJudgeNumQuestions ||
                          errors.sitJudgeNumQuestionsFormat
                        }
                      />
                      {errors.sitJudgeNumQuestions && (
                        <Form.Text className="text-danger">
                          {errors.sitJudgeNumQuestions}
                        </Form.Text>
                      )}
                      {errors.sitJudgeNumQuestionsFormat && (
                        <Form.Text className="text-danger">
                          {errors.sitJudgeNumQuestionsFormat}
                        </Form.Text>
                      )}
                      <Form.Text className="text-muted">
                        Specify the total number of questions in the Situational
                        Judgement Test.
                      </Form.Text>
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>Difficulty Distribution</Form.Label>
                      <Row>
                        {/* Easy Difficulty */}
                        <Col xs={12} sm={4}>
                          <Form.Label>
                            Easy
                            <span className="text-danger scale5 ms-2">*</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Easy"
                            value={
                              APTMCQConfig.questionsConfig.situationalJudgment
                                ?.difficultyDistribution?.easy
                            }
                            onChange={(e) => {
                              const inputValue = e.target.value;

                              // Remove any non-digit characters
                              const filteredValue = inputValue.replace(
                                /[^0-9]/g,
                                ''
                              );

                              setAPTMCQConfig({
                                ...APTMCQConfig,
                                questionsConfig: {
                                  ...APTMCQConfig.questionsConfig,
                                  situationalJudgment: {
                                    ...APTMCQConfig.questionsConfig[
                                      'situationalJudgment'
                                    ],
                                    difficultyDistribution: {
                                      ...APTMCQConfig.questionsConfig[
                                        'situationalJudgment'
                                      ]?.difficultyDistribution,
                                      easy:
                                        filteredValue === ''
                                          ? ''
                                          : parseInt(filteredValue)
                                    }
                                  }
                                }
                              });

                              setErrors((prevErrors) => ({
                                ...prevErrors,
                                sitJudgeEasyDifficulty:
                                  filteredValue === ''
                                    ? 'This is a required field'
                                    : +filteredValue > 20
                                      ? 'This value cannot exceed 20'
                                      : '',
                                sitJudgeEasyDifficultyFormat: ''
                              }));
                            }}
                            isInvalid={
                              errors.sitJudgeEasyDifficulty ||
                              errors.sitJudgeEasyDifficultyFormat
                            }
                          />
                          {errors.sitJudgeEasyDifficulty && (
                            <Form.Text className="text-danger">
                              {errors.sitJudgeEasyDifficulty}
                            </Form.Text>
                          )}
                          {errors.sitJudgeEasyDifficultyFormat && (
                            <Form.Text className="text-danger">
                              {errors.sitJudgeEasyDifficultyFormat}
                            </Form.Text>
                          )}
                        </Col>

                        {/* Medium Difficulty */}
                        <Col xs={12} sm={4}>
                          <Form.Label>
                            Medium
                            <span className="text-danger scale5 ms-2">*</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Medium"
                            className="mt-2 mt-sm-0"
                            value={
                              APTMCQConfig.questionsConfig.situationalJudgment
                                ?.difficultyDistribution?.medium
                            }
                            onChange={(e) => {
                              const inputValue = e.target.value;

                              // Remove any non-digit characters
                              const filteredValue = inputValue.replace(
                                /[^0-9]/g,
                                ''
                              );

                              setAPTMCQConfig({
                                ...APTMCQConfig,
                                questionsConfig: {
                                  ...APTMCQConfig.questionsConfig,
                                  situationalJudgment: {
                                    ...APTMCQConfig.questionsConfig[
                                      'situationalJudgment'
                                    ],
                                    difficultyDistribution: {
                                      ...APTMCQConfig.questionsConfig[
                                        'situationalJudgment'
                                      ]?.difficultyDistribution,
                                      medium:
                                        filteredValue === ''
                                          ? ''
                                          : parseInt(filteredValue)
                                    }
                                  }
                                }
                              });

                              setErrors((prevErrors) => ({
                                ...prevErrors,
                                sitJudgeMediumDifficulty:
                                  filteredValue === ''
                                    ? 'This is a required field'
                                    : +filteredValue > 20
                                      ? 'This value cannot exceed 20'
                                      : '',
                                sitJudgeMediumDifficultyFormat: ''
                              }));
                            }}
                            isInvalid={
                              errors.sitJudgeMediumDifficulty ||
                              errors.sitJudgeMediumDifficultyFormat
                            }
                          />
                          {errors.sitJudgeMediumDifficulty && (
                            <Form.Text className="text-danger">
                              {errors.sitJudgeMediumDifficulty}
                            </Form.Text>
                          )}
                          {errors.sitJudgeMediumDifficultyFormat && (
                            <Form.Text className="text-danger">
                              {errors.sitJudgeMediumDifficultyFormat}
                            </Form.Text>
                          )}
                        </Col>

                        {/* Hard Difficulty */}
                        <Col xs={12} sm={4}>
                          <Form.Label>
                            Hard
                            <span className="text-danger scale5 ms-2">*</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Hard"
                            className="mt-2 mt-sm-0"
                            value={
                              APTMCQConfig.questionsConfig.situationalJudgment
                                ?.difficultyDistribution?.hard
                            }
                            onChange={(e) => {
                              const inputValue = e.target.value;

                              // Remove any non-digit characters
                              const filteredValue = inputValue.replace(
                                /[^0-9]/g,
                                ''
                              );

                              setAPTMCQConfig({
                                ...APTMCQConfig,
                                questionsConfig: {
                                  ...APTMCQConfig.questionsConfig,
                                  situationalJudgment: {
                                    ...APTMCQConfig.questionsConfig[
                                      'situationalJudgment'
                                    ],
                                    difficultyDistribution: {
                                      ...APTMCQConfig.questionsConfig[
                                        'situationalJudgment'
                                      ]?.difficultyDistribution,
                                      hard:
                                        filteredValue === ''
                                          ? ''
                                          : parseInt(filteredValue)
                                    }
                                  }
                                }
                              });

                              setErrors((prevErrors) => ({
                                ...prevErrors,
                                sitJudgeHardDifficulty:
                                  filteredValue === ''
                                    ? 'This is a required field'
                                    : +filteredValue > 20
                                      ? 'This value cannot exceed 20'
                                      : '',
                                sitJudgeHardDifficultyFormat: ''
                              }));
                            }}
                            isInvalid={
                              errors.sitJudgeHardDifficulty ||
                              errors.sitJudgeHardDifficultyFormat
                            }
                          />
                          {errors.sitJudgeHardDifficulty && (
                            <Form.Text className="text-danger">
                              {errors.sitJudgeHardDifficulty}
                            </Form.Text>
                          )}
                          {errors.sitJudgeHardDifficultyFormat && (
                            <Form.Text className="text-danger">
                              {errors.sitJudgeHardDifficultyFormat}
                            </Form.Text>
                          )}
                        </Col>
                      </Row>
                      <Form.Text className="text-muted mt-2">
                        Distribute the difficulty levels among the questions.
                      </Form.Text>
                    </Form.Group>
                  </div>
                )}
              </Form.Group>
            </div>
          )}
        </Form.Group>

        <div className="d-flex justify-content-end">
          <Button
            variant="primary"
            className="px-4 py-2"
            onClick={updateTestSteps}
            disabled={hasError}
          >
            Save Configuration
          </Button>
        </div>
      </Card>
      {/* </Container> */}
    </>
  );
}

export default AdvancedJobConfig;
