import React, { useEffect, useRef, useState, useCallback } from 'react';
import { Link, useParams, useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import './loader.css';
import { Dropdown, Spinner } from 'react-bootstrap';
import AIJobDescriptionGenerator from './AIJobDescriptionGenerator';
import { FaChevronUp } from 'react-icons/fa';
import './tabs.css';
import AdvancedJobConfig from './AdvancedJobConfig';
import { formatNumberWithCommas } from '../../../utils/CommonFunctions';

const NewJob = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isCloneJob = location.pathname.includes('/clone-job/');
  const isEditJob = location.pathname.includes('/update-job');
  const isNewJob = location.pathname.includes('/new-job');
  const { jobId } = useParams();
  const [title, setTitle] = useState('');
  const [userLocation, setUserLocation] = useState(' ');
  const [isRemoteAllowed, setIsRemoteAllowed] = useState(null);
  const [query, setQuery] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [focused, setFocused] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedCity, setSelectedCity] = useState('');
  const [locationSelected, setLocationSelected] = useState(false);
  const [activeIndex, setActiveIndex] = useState(-1);
  const inputRef = useRef(null);
  const dropdownRef = useRef(null);
  const [perks, setPerks] = useState('');
  const [joiningDeadline, setJoiningDeadline] = useState(null);
  const [timeZone, setTimeZone] = useState('');
  const [currentCompany, setCurrentCompany] = useState('');
  const [loadingOrganizationDetails, setLoadingOrganizationDetailsFlag] =
    useState(false);
  const [minimumExperience, setMinimumExperience] = useState(null);
  const [maximumExperience, setMaximumExperience] = useState(null);
  const [jobType, setJobType] = useState('Select Job Type');
  const [workHours, setWorkHours] = useState('');
  const [workLocationType, setWorkLocationType] = useState('');
  const [isValid, setIsValid] = useState(true);
  const [minimumSalary, setMinimumSalary] = useState(null);
  const [maximumSalary, setMaximumSalary] = useState(null);
  const [minimumSalaryDisplay, setMinimumSalaryDisplay] = useState('');
  const [maximumSalaryDisplay, setMaximumSalaryDisplay] = useState('');
  const [description, setDescription] = useState('');
  const [responsibilities, setResponsibilities] = useState('');
  const [qualifications, setQualifications] = useState('');
  const [status, setStatus] = useState('');
  const [errorCityDropdown, setErrorCityDropdown] = useState(false);
  const [loadingCities, setLoadingCitiesFlag] = useState(false);
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState('basic');
  const [testProcesses, setTestProcesses] = useState([]);
  const [minimumExperienceValidation, setMinimumExperienceValidation] =
    useState('');
  const [maximumExperienceValidation, setMaximumExperienceValidation] =
    useState('');
  const [initialFormData, setInitialFormData] = useState({});

  const currencySymbol = localStorage.getItem('currency') || 'INR';

  const userDetails = JSON.parse(localStorage.getItem('userDetails'));
  const orgId = userDetails?.user?.organizationId;

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
        setErrorCityDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (isNewJob) {
      setActiveTab('basic');
    }
  });

  useEffect(() => {
    const fetchOrgDetails = async () => {
      try {
        setLoadingOrganizationDetailsFlag(true);
        const accessToken = userDetails?.session.accessToken || null;
        if (!accessToken) {
          throw new Error('Access token not found');
        }

        const response = await fetch(
          `${process.env.REACT_APP_DEV_API}/v1/organization/${orgId}`,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${accessToken}`
            }
          }
        );

        if (!response.ok) {
          throw new Error('Failed to fetch organization details');
        }

        const { data } = await response.json();
        const organizationDetails = data.organization;
        if (organizationDetails) {
          const organizationName = organizationDetails.name;
          setCurrentCompany(organizationName);
        } else {
          setCurrentCompany('');
        }
      } catch (error) {
        console.error('Error fetching organization details:', error);
      } finally {
        setLoadingOrganizationDetailsFlag(false);
      }
    };

    if (isNewJob) {
      fetchOrgDetails();
    }
  }, [isNewJob, location]);

  const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  useEffect(() => {
    if (showDropdown && dropdownRef.current) {
      const activeElement = dropdownRef.current.querySelector('.active');
      if (activeElement) {
        activeElement.scrollIntoView({ block: 'nearest' });
      }
    }
  }, [activeIndex, showDropdown]);

  useEffect(() => {
    const fetchJobDetails = async () => {
      setLoading(true);
      const URL = `${process.env.REACT_APP_DEV_API}/v1/organization/${orgId}/job/${jobId}`;
      try {
        const response = await fetch(URL);
        const data = await response.json();
        if (response.ok) {
          setLoading(false);
          setTitle(isCloneJob ? `${data.data.title} Copy` : data.data.title);
          setQuery(data.data.location);
          setIsRemoteAllowed(data.data.isRemoteAllowed);
          setPerks(data.data.perks);
          setUserLocation(data.data.location);
          setTimeZone(data.data.timeZone);
          setCurrentCompany(data.data.companyName);
          setMinimumExperience(data.data.minimumExperience ?? null);
          setMaximumExperience(data.data.maximumExperience ?? null);
          setJobType(data.data.jobType);
          setWorkHours(data.data.workHours);
          setWorkLocationType(data.data.workLocationType);
          setMaximumSalary(data.data.maximumSalary);
          setMinimumSalary(data.data.minimumSalary);
          setJoiningDeadline(data.data.joiningDeadline);
          setMaximumSalaryDisplay(
            formatNumberWithCommas(data.data.maximumSalary || null)
          );
          setMinimumSalaryDisplay(
            formatNumberWithCommas(data.data.minimumSalary || null)
          );
          setDescription(data.data.description);
          setResponsibilities(
            formatResponsibilities(data.data.responsibilities)
          );
          setQualifications(formatQualifications(data.data.qualifications));
          setTestProcesses(data.data.testProcesses);

          setInitialFormData({
            title: data.data.title,
            location: data.data.location,
            isRemoteAllowed: data.data.isRemoteAllowed,
            perks: data.data.perks,
            timeZone: data.data.timeZone,
            minimumExperience: data.data.minimumExperience ?? null,
            maximumExperience: data.data.maximumExperience ?? null,
            jobType: data.data.jobType,
            workHours: data.data.workHours,
            workLocationType: data.data.workLocationType,
            maximumSalary: data.data.maximumSalary,
            minimumSalary: data.data.minimumSalary,
            joiningDeadline: data.data.joiningDeadline,
            description: data.data.description,
            responsibilities: formatResponsibilities(
              data.data.responsibilities
            ),
            qualifications: formatQualifications(data.data.qualifications),
            currentCompany: data.data.companyName
          });
        } else {
          setLoading(false);
          toast.error(data.message);
        }
      } catch (error) {
        setLoading(false);
        navigate('/page-error-404');
        toast.error('An error occurred while fetching job details.');
      }
    };

    const resetForm = () => {
      setTitle('');
      setUserLocation('');
      setIsRemoteAllowed(null);
      setPerks('');
      setQuery('');
      setTimeZone('');
      setMinimumExperience(null);
      setMaximumExperience(null);
      setJobType('');
      setWorkHours('');
      setWorkLocationType('');
      setMaximumSalary(null);
      setMinimumSalary(null);
      setMaximumSalaryDisplay('');
      setMinimumSalaryDisplay('');
      setDescription('');
      setJoiningDeadline(null);
      setResponsibilities('');
      setQualifications('');
      setStatus('');
      setCurrentCompany('');
    };

    if (jobId) {
      fetchJobDetails();
    } else {
      resetForm();
      setErrorCityDropdown(false);
      setMinimumExperienceValidation('');
      setMaximumExperienceValidation('');
      setLoading(false);
    }
  }, [jobId, isCloneJob, orgId, navigate]);

  const hasChanges = () => {
    return (
      title !== initialFormData.title ||
      query !== initialFormData.location ||
      isRemoteAllowed !== initialFormData.isRemoteAllowed ||
      perks !== initialFormData.perks ||
      timeZone !== initialFormData.timeZone ||
      minimumExperience !== initialFormData.minimumExperience ||
      maximumExperience !== initialFormData.maximumExperience ||
      jobType !== initialFormData.jobType ||
      workHours !== initialFormData.workHours ||
      workLocationType !== initialFormData.workLocationType ||
      maximumSalary !== initialFormData.maximumSalary ||
      minimumSalary !== initialFormData.minimumSalary ||
      joiningDeadline !== initialFormData.joiningDeadline ||
      description !== initialFormData.description ||
      responsibilities !== initialFormData.responsibilities ||
      qualifications !== initialFormData.qualifications ||
      currentCompany !== initialFormData.currentCompany
    );
  };

  const extractCity = (displayName) => {
    return displayName.split(',')[0].trim();
  };

  const handleInputChange = (e) => {
    const city = e.target.value;
    setQuery(city);
    // setUserLocation("");
    setSelectedCity('');
    setActiveIndex(-1);
    setIsValid(false);
    setLocationSelected(false);

    if (city) {
      setLoadingCitiesFlag(true);
      debouncedFetchSuggestions(city);
      setShowDropdown(true);
    } else {
      setSuggestions([]);
      // setShowDropdown(false);
    }
  };

  const handleSelectSuggestion = (suggestion) => {
    const selectedCity = extractCity(suggestion.displayName);
    setSelectedCity(selectedCity);
    setQuery(suggestion.displayName);

    setUserLocation(suggestion.displayName);
    // setSuggestions([]);
    setShowDropdown(false);
    setActiveIndex(-1);
    setIsValid(true);
    setLocationSelected(true);
    // inputRef.current.focus();
  };

  const handleBlur = (e) => {
    if (!dropdownRef.current.contains(e.relatedTarget)) {
      setTimeout(() => setShowDropdown(false), 100);
    }
  };

  const handleFocus = () => {
    if (suggestions.length > 0) {
      setShowDropdown(true);
    }
  };

  const handleClose = () => {
    navigate('/job-list');
  };

  const formatQualifications = (text) => {
    return text.replace(/\. /g, '.\n');
  };

  const formatResponsibilities = (text) => {
    return text.replace(/\. /g, '.\n');
  };

  const handleQualificationsChange = (e) => {
    const newValue = e.target.value;
    const cursorPosition = e.target.selectionStart;
    const formattedText = formatQualifications(newValue);
    setQualifications(formattedText);
    setTimeout(() => {
      const newPosition =
        formattedText.length - (newValue.length - cursorPosition);
      e.target.setSelectionRange(newPosition, newPosition);
    }, 0);
  };

  const handleResponsibilityChange = (e) => {
    const newValue = e.target.value;
    const cursorPosition = e.target.selectionStart;
    const formattedText = formatResponsibilities(newValue);
    setResponsibilities(formattedText);
    setTimeout(() => {
      const newPosition =
        formattedText.length - (newValue.length - cursorPosition);
      e.target.setSelectionRange(newPosition, newPosition);
    }, 0);
  };

  const fetchSuggestions = async (city, enableDropdownFlag = true) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_DEV_API}/v1/default/city-suggestions?query=${city}`,
        {
          method: 'Get'
        }
      );
      const data = await response.json();
      setSuggestions(data.data);
      enableDropdownFlag && setShowDropdown(true);
    } catch (error) {
      toast.error('Error fetching suggestions', error);
    } finally {
      setLoadingCitiesFlag(false);
    }
  };

  const debouncedFetchSuggestions = useCallback(
    debounce(fetchSuggestions, 1000),
    []
  );

  const handleKeyDown = (e) => {
    if (!showDropdown) {
      return;
    }

    const inputElement = e.target;
    const isAllTextSelected =
      inputElement.selectionStart === 0 &&
      inputElement.selectionEnd === inputElement.value.length;
    if (e.key === 'ArrowDown') {
      e.preventDefault();
      setActiveIndex((prevIndex) =>
        prevIndex < suggestions.length - 1 ? prevIndex + 1 : prevIndex
      );
    } else if (e.key === 'ArrowUp') {
      e.preventDefault();
      setActiveIndex((prevIndex) =>
        prevIndex > 0 ? prevIndex - 1 : suggestions.length - 1
      );
    } else if (e.key === 'Enter' && activeIndex >= 0) {
      e.preventDefault();
      handleSelectSuggestion(suggestions[activeIndex]);
    } else if (e.key === 'Escape') {
      setShowDropdown(false);
    } else if (e.key === 'Backspace') {
      if (isAllTextSelected) {
        setQuery('');
        setSuggestions([]);
      } else {
        const city = extractCity(query);
        setQuery(city);
        setSelectedCity(city);
        setLoadingCitiesFlag(true);
        debouncedFetchSuggestions(city);
        setShowDropdown(true);
      }
    }
  };

  const handleQualificationsPaste = (e) => {
    e.preventDefault();
    const pastedText = e.clipboardData.getData('text');
    const formattedText = formatQualifications(pastedText);
    setQualifications(formattedText);
  };

  const formatText = (text) => {
    return text.replace(/\.\n/g, '. ').trim();
  };

  const handleResponsibilityPaste = (e) => {
    e.preventDefault();
    const pastedText = e.clipboardData.getData('text');
    const formattedText = formatResponsibilities(pastedText);
    setResponsibilities(formattedText);
  };

  const showEmptyError = (field, message) => {
    if (typeof field === 'boolean') {
      return false;
    }

    if (field === '' || field === null || field === undefined) {
      toast.error(message);
      return true;
    }
    return false;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    //this means user has selected a value from dropdown
    if (title === '') {
      toast.error('Please enter a title for the job posting.');
      return;
    }
    if (locationSelected) {
      const selectedCityFromSuggestions =
        suggestions && suggestions.find((city) => city.displayName === query);
      if (query && !selectedCityFromSuggestions) {
        setErrorCityDropdown(true);
        toast.error('Please select city from the given suggestions.');
        return;
      }
    } else {
      //this means the input field value and the stored value are changed || the input field is dirty
      if (userLocation !== query) {
        const selectedCityFromSuggestions =
          suggestions &&
          suggestions.find(
            (city) =>
              city.displayName === query || city.displayName.includes(query)
          );
        if (selectedCityFromSuggestions) {
          toast.error('Select city from suggestions.');
          return;
        } else {
          toast.error('Please specify a location for the job.');
          setErrorCityDropdown(true);
          return;
        }
      }
    }

    if (minimumExperience !== null && minimumExperience !== '') {
      if (isNaN(minimumExperience) || minimumExperience < 0) {
        toast.error('Minimum experience must be a non-negative number');
        return;
      }
    }

    if (!/^\d+$/.test(minimumExperience)) {
      toast.error(
        'Please enter a valid number for minimum years of experience.'
      );
      return;
    }

    if (
      showEmptyError(timeZone, 'Please select a time zone for this position.')
    ) {
      return;
    }
    if (
      showEmptyError(
        joiningDeadline,
        'Please select a joining deadline for this position.'
      )
    ) {
      return;
    }
    if (showEmptyError(description, 'Please provide a job description.')) {
      return;
    }
    if (
      showEmptyError(responsibilities, 'Please list the job responsibilities.')
    ) {
      return;
    }
    if (
      showEmptyError(
        qualifications,
        'Please specify the required qualifications.'
      )
    ) {
      return;
    }
    if (
      showEmptyError(
        isRemoteAllowed,
        'Please indicate whether remote work is allowed (Yes/No).'
      )
    ) {
      return;
    }
    if (
      showEmptyError(
        jobType,
        'Please select a job type (Permanent, Contractual, or Intern).'
      )
    ) {
      return;
    }
    if (
      showEmptyError(
        workHours,
        'Please specify the work hours (Full-time or Part-time).'
      )
    ) {
      return;
    }
    if (
      showEmptyError(
        workLocationType,
        'Please select a work location type (On-site, Remote, or Hybrid).'
      )
    ) {
      return;
    }

    if (!joiningDeadline) {
      toast.error('Please select a joining deadline');
      return;
    }

    if (maximumExperience !== null && maximumExperience !== '') {
      if (isNaN(maximumExperience) || maximumExperience < 0) {
        toast.error('Maximum experience must be a non-negative number');
        return;
      }
      if (
        minimumExperience !== null &&
        minimumExperience !== '' &&
        Number(minimumExperience) > Number(maximumExperience)
      ) {
        toast.error(
          'Maximum experience must be greater than or equal to minimum experience'
        );
        return;
      }
    }

    if (minimumSalary !== null && minimumSalary !== '') {
      if (isNaN(minimumSalary) || Number(minimumSalary) <= 0) {
        toast.error('Minimum Yearly salary must be a positive number');
        return;
      }
    }

    if (maximumSalary !== null && maximumSalary !== '') {
      if (isNaN(maximumSalary) || Number(maximumSalary) <= 0) {
        toast.error('Maximum Yearly salary must be a positive number');
        return;
      }
      if (
        minimumSalary !== null &&
        minimumSalary !== '' &&
        Number(minimumSalary) > Number(maximumSalary)
      ) {
        toast.error(
          'Maximum Yearly salary must be greater than or equal to minimum Yearly salary'
        );
        return;
      }
    }

    if (maximumExperienceValidation || minimumExperienceValidation) {
      return;
    }

    const method = isCloneJob ? 'POST' : jobId ? 'PUT' : 'POST';
    const url =
      isCloneJob || !jobId
        ? `${process.env.REACT_APP_DEV_API}/v1/organization/${orgId}/job`
        : `${process.env.REACT_APP_DEV_API}/v1/organization/${orgId}/job/${jobId}`;

    try {
      setLoading(true);
      const userDetails = JSON.parse(localStorage.getItem('userDetails'));
      const accessToken = userDetails?.session.accessToken || null;
      if (!accessToken) {
        throw new Error('Access token not found');
      }

      const payload = {
        title,
        location: userLocation,
        isRemoteAllowed,
        perks,
        timeZone,
        minimumExperience,
        workHours,
        workLocationType,
        jobType,
        maximumSalary,
        companyName: currentCompany,
        description,
        responsibilities: formatText(responsibilities),
        qualifications: formatText(qualifications),
        minimumSalary: minimumSalary === '' ? null : minimumSalary,
        maximumSalary: maximumSalary === '' ? null : maximumSalary,
        minimumExperience: minimumExperience === '' ? null : minimumExperience,
        maximumExperience: maximumExperience === '' ? null : maximumExperience,
        joiningDeadline: parseInt(joiningDeadline, 10)
      };

      const response = await fetch(url, {
        method,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`
        },
        body: JSON.stringify(payload)
      });

      const data = await response.json();
      if (response.ok) {
        setActiveTab('advanced');
        setLoading(false);
        navigate(
          data?.data?._id ? `/update-job/${data?.data?._id}` : '/job-list'
        );
        toast.success(
          isCloneJob
            ? 'Job cloned successfully'
            : jobId
              ? 'Job updated successfully'
              : 'Job created successfully'
        );
      } else {
        setLoading(false);
        if (data.message.includes('location')) {
          toast.error('Please specify a location for the job.');
        } else if (data.message.includes('maximumSalary')) {
          toast.error('Maximum Yearly Salary must be a number');
        } else if (data.message.includes('minimumSalary')) {
          toast.error('Minimum Yearly Salary must be a number');
        } else {
          toast.error(data.message);
        }
      }
    } catch (error) {
      setLoading(false);
      toast.error(
        `An error occurred while ${
          isCloneJob ? 'cloning' : isEditJob ? 'updating' : 'creating'
        } the job.`
      );
      navigate('/page-error-404');
    }
  };

  const formatIndianCurrency = (num) => {
    if (num === null || num === '') {
      return '';
    }
    return num.toLocaleString('en-IN');
  };

  const formatStringForFrontend = (str) => {
    const words = str.split('_');

    const formatWord = (word) => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    };

    const formattedWords = words.map((word) => {
      if (word.includes(' ')) {
        return word
          .split(' ')
          .map((w) => {
            return formatWord(w);
          })
          .join(' ');
      } else {
        return formatWord(word);
      }
    });

    return formattedWords.join(' ');
  };

  const handleMinimumSalaryChange = (e) => {
    const value = e.target.value.replace(/[^0-9]/g, '');
    if (value === '' || parseInt(value) >= 0) {
      setMinimumSalary(value === '' ? null : parseInt(value));
      setMinimumSalaryDisplay(formatNumberWithCommas(parseInt(value) || null));
    }
  };

  const handleMaximumSalaryChange = (e) => {
    const value = e.target.value.replace(/[^0-9]/g, '');
    if (value === '' || parseInt(value) >= 0) {
      setMaximumSalary(value === '' ? null : parseInt(value));
      setMaximumSalaryDisplay(formatNumberWithCommas(parseInt(value) || null));
    }
  };

  const triggerValidationsForMinimumExperienceField = (minimumValue) => {
    const minValue = parseInt(minimumValue);
    const maxValue = parseInt(maximumExperience);
    setMaximumExperienceValidation('');
    if (minValue > maxValue) {
      setMinimumExperienceValidation(
        'Minimum Experience cannot be greater then Maximum Experience.'
      );
    } else if (minValue === maxValue) {
      setMinimumExperienceValidation(
        'Minimum and maximum experience values cannot be the same.'
      );
    } else {
      setMinimumExperienceValidation('');
    }
  };

  const triggerValidationsForMaximumExperienceField = (maximumValue) => {
    const minValue = parseInt(minimumExperience);
    const maxValue = parseInt(maximumValue);
    setMinimumExperienceValidation('');
    if (minValue === maxValue) {
      setMaximumExperienceValidation(
        'Minimum and maximum experience values cannot be the same.'
      );
    } else if (minValue > maxValue) {
      setMaximumExperienceValidation(
        'Maximum Experience cannot be less then Minimum Experience.'
      );
    } else {
      setMaximumExperienceValidation('');
    }
  };

  const handleMinimumExperienceChange = (e) => {
    const value = e.target.value;
    triggerValidationsForMinimumExperienceField(value);
    setMinimumExperience(value === '' ? '' : parseFloat(value));
  };

  const handleMaximumExperienceChange = (e) => {
    const value = e.target.value;
    triggerValidationsForMaximumExperienceField(value);
    setMaximumExperience(value === '' ? '' : parseFloat(value));
  };

  const handleSalaryPaste = (e, setter, displaySetter) => {
    e.preventDefault();
    const pastedText = e.clipboardData.getData('text').replace(/\D/g, '');
    if (pastedText === '' || parseInt(pastedText) >= 0) {
      const value = pastedText === '' ? null : parseInt(pastedText);
      setter(value);
      displaySetter(formatNumberWithCommas(value || 0));
    }
  };

  const preFillJobDetailsFormWithLatestDetails = (jobDetails) => {
    setUserLocation(jobDetails?.location);
    setIsRemoteAllowed(jobDetails?.isRemoteAllowed);
    setPerks(jobDetails?.perks);
    setQuery(jobDetails?.location);
    setTimeZone(jobDetails?.timeZone);
    setJobType(jobDetails?.jobType);
    setWorkHours(jobDetails?.workHours);
    setWorkLocationType(jobDetails.workLocationType);
    setJoiningDeadline(jobDetails.joiningDeadline);
    setStatus(jobDetails.status);
  };

  const handleAIDescriptionGenerated = (generatedData) => {
    setTitle(generatedData.title);
    setDescription(generatedData.description);
    setQualifications(formatQualifications(generatedData.qualifications));
    setResponsibilities(formatResponsibilities(generatedData.responsibilities));
  };

  return (
    <>
      <style>
        {`input.form-control,
          textarea.form-control,
          .custom-dropdown-toggle {
            font-family: inherit;
            font-size: 0.875rem !important;
            color: gray;
          }

          input.form-control::placeholder,
          textarea.form-control::placeholder,
          .custom-dropdown-toggle {
            color: lightgray;
          }

          input.location-input::placeholder {
          color: lightgray;
          opacity: 1;
}


        .dropdown-menu {
        font-size: 0.875rem !important;
        }

        .dropdown-item {
          padding: 0.5rem 1rem;
        }
  
          .custom-dropdown-menu {
            transform: translate3d(0px, 30px, 0px) !important;
            min-width: 100% !important;
            font-style : inherit
          }
          
          .dropdown-item.active {
          background-color: #f8f9fa;
          color: #16181b;
          }

          .dropdown-item.active:before {
          content: '✓ ';
          margin-right: 5px;
          
          }

          .custom-dropdown-toggle {
            width: 100% !important;
            color:lightgray;
            font-weight : 400;
            font-size: 0.875 rem;
            font-style : inherit
            }
        `}
      </style>
      <div
        className={`flex-column align-items-center mb-4 ${
          !loading ? 'd-none' : 'd-flex'
        }`}
      >
        <h3 className="mb-0 me-auto">
          {isCloneJob ? 'Clone Job' : isEditJob ? 'Edit Job' : 'Create Job'}
        </h3>
        <div className="d-flex flex-column align-items-center justify-content-center gap-5 mt-5 pt-5">
          <div className="mt-5 mb-5 pt-5 pb-4">
            <div className="mt-2"></div>
          </div>
          <Spinner animation="border mb-3" style={{ color: '#858585' }} />
        </div>
      </div>
      <div className={`${loading ? 'd-none' : ''}`}>
        <div className="d-flex align-items-center mb-4">
          <h3 className="mb-0 me-auto">
            {isCloneJob ? 'Clone Job' : isEditJob ? 'Edit Job' : 'Create Job'}
          </h3>
          <div>
            {location.pathname.includes('/new-job') && (
              <AIJobDescriptionGenerator
                onDescriptionGenerated={handleAIDescriptionGenerated}
                preFillJobForm={preFillJobDetailsFormWithLatestDetails}
                activeTab={activeTab}
              />
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-xl-12">
            {isCloneJob ? (
              <></>
            ) : isEditJob ? (
              <ul
                className="nav nav-tabs d-flex justify-content-start border-0"
                id="myTab"
                role="tablist"
              >
                <li className="nav-item" role="presentation">
                  <button
                    className={`nav-link ${
                      activeTab === 'basic'
                        ? 'chrome-tab-active'
                        : 'chrome-tab-inactive'
                    }`}
                    id="basic-tab"
                    data-bs-toggle="tab"
                    type="button"
                    role="tab"
                    onClick={() => handleTabClick('basic')}
                  >
                    Define Job
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className={`nav-link ${
                      activeTab === 'advanced'
                        ? 'chrome-tab-active'
                        : 'chrome-tab-inactive'
                    }`}
                    id="advanced-tab"
                    data-bs-toggle="tab"
                    type="button"
                    role="tab"
                    onClick={() => handleTabClick('advanced')}
                  >
                    Configure Tests
                  </button>
                </li>
              </ul>
            ) : (
              <></>
            )}

            <div className="tab-content rounded">
              <div
                className={`tab-pane fade ${
                  activeTab === 'basic' ? 'show active' : ''
                }`}
                id="basic"
                role="tabpanel"
              >
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-xl-6  col-md-6 mb-4">
                        <label className="form-label font-w500">
                          Job Title
                          <span className="text-danger scale5 ms-2">*</span>
                        </label>
                        <input
                          type="text"
                          name="name"
                          value={title}
                          onChange={(e) => setTitle(e.target.value)}
                          className="form-control solid"
                          placeholder="Software Developer"
                          aria-label="name"
                        />
                      </div>

                      <div
                        className="col-xl-6 col-md-6 mb-4"
                        onKeyDown={handleKeyDown}
                      >
                        <label className="form-label font-w500">
                          Location
                          <span className="text-danger scale5 ms-2">*</span>
                        </label>
                        <Dropdown
                          show={showDropdown}
                          ref={dropdownRef}
                          onToggle={(isOpen) => setShowDropdown(isOpen)}
                        >
                          <Dropdown.Toggle
                            as="div"
                            className={`d-flex align-items-center justify-content-between form-control solid ${
                              errorCityDropdown ? 'border border-danger' : ''
                            } `}
                          >
                            <input
                              ref={inputRef}
                              type="text"
                              value={query}
                              onChange={handleInputChange}
                              // onFocus={() => setShowDropdown(true)}
                              className="border-0 flex-grow-1 location-input"
                              placeholder="Enter city"
                              aria-label="name"
                              style={{
                                outline: 'none',
                                boxShadow: 'none',
                                color: 'gray',
                                '::placeholder': { color: 'lightgray' }
                              }}
                            />
                          </Dropdown.Toggle>
                          <Dropdown.Menu
                            className="w-100"
                            style={{ maxHeight: '200px', overflowY: 'auto' }}
                          >
                            {!query ? (
                              <div className="px-4 py-2 text-sm text-gray-500">
                                Type your city
                              </div>
                            ) : loadingCities ? (
                              <div className="d-flex justify-content-center w-100 align-items-center">
                                <Spinner
                                  animation="border"
                                  style={{ color: '#858585' }}
                                />
                              </div>
                            ) : suggestions.length > 0 ? (
                              suggestions.map((suggestion, index) => (
                                <Dropdown.Item
                                  key={index}
                                  active={index === activeIndex}
                                  onClick={() =>
                                    handleSelectSuggestion(suggestion)
                                  }
                                >
                                  {suggestion.displayName}
                                </Dropdown.Item>
                              ))
                            ) : (
                              <Dropdown.Item>
                                No suggestions found.
                              </Dropdown.Item>
                            )}
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                      <div className="col-xl-6  col-md-6 mb-4">
                        <label className="form-label font-w500">
                          Minimum Experience(in years)
                          <span className="text-danger scale5 ms-2">*</span>
                        </label>
                        <input
                          type="number"
                          name="experience"
                          value={
                            minimumExperience !== null ? minimumExperience : ''
                          }
                          onChange={handleMinimumExperienceChange}
                          onKeyProcess={
                            minimumExperience === null ||
                            minimumExperience === ''
                              ? '0'
                              : ''
                          }
                          className={`form-control solid ${
                            minimumExperienceValidation
                              ? 'border border-danger'
                              : ''
                          }`}
                          placeholder={
                            minimumExperience === null ||
                            minimumExperience === ''
                              ? '5'
                              : ''
                          }
                          min="0"
                          aria-label="name"
                        />
                        {minimumExperienceValidation && (
                          <span className="text-danger mt-1">
                            {minimumExperienceValidation}
                          </span>
                        )}
                      </div>
                      <div className="col-xl-6  col-md-6 mb-4">
                        <label className="form-label font-w500">
                          Maximum Experience(in years)
                        </label>
                        <input
                          type="number"
                          name="experience"
                          value={
                            maximumExperience !== null ? maximumExperience : ''
                          }
                          onChange={handleMaximumExperienceChange}
                          onKeyProcess={
                            maximumExperience === null ||
                            maximumExperience === ''
                              ? '5'
                              : ''
                          }
                          className={`form-control solid ${
                            maximumExperienceValidation
                              ? 'border border-danger'
                              : ''
                          }`}
                          placeholder={
                            maximumExperience === null ||
                            maximumExperience === ''
                              ? '5'
                              : ''
                          }
                          min="0"
                          aria-label="name"
                        />
                        {maximumExperienceValidation && (
                          <span className="text-danger mt-2">
                            {maximumExperienceValidation}
                          </span>
                        )}
                      </div>
                      <div className="col-xl-6 col-md-6 mb-4">
                        <label className="form-label font-w500">
                          Job Type
                          <span className="text-danger scale5 ms-2">*</span>
                        </label>
                        <Dropdown
                          className="form-control solid style-1"
                          style={{ display: 'flex', alignItems: 'center' }}
                        >
                          <Dropdown.Toggle
                            as="div"
                            className="custom-dropdown-toggle fs-16 font-w500  justify-content-between d-flex align-items-center"
                            style={{
                              color: jobType === '' ? 'lightgray' : 'gray',
                              fontSize: '0.875rem !important'
                            }}
                            tabIndex="0"
                            onKeyDown={(e) => {
                              if (e.key === 'Enter' || e.key === ' ') {
                                e.preventDefault();
                                e.target.click();
                              }
                            }}
                          >
                            {jobType
                              ? formatStringForFrontend(jobType)
                              : 'Choose a Value'}
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="custom-dropdown-menu dropdown-menu dropdown-menu-end mt-1">
                            {['PERMANENT', 'CONTRACTUAL', 'INTERN'].map(
                              (type) => (
                                <Dropdown.Item
                                  key={type}
                                  className={jobType === type ? 'active' : ''}
                                  onClick={() => setJobType(type)}
                                >
                                  {formatStringForFrontend(type)}
                                </Dropdown.Item>
                              )
                            )}
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                      <div className="col-xl-6 col-md-6 mb-4">
                        <label className="form-label font-w500">
                          Is Remote Allowed
                          <span className="text-danger scale5 ms-2">*</span>
                        </label>
                        <Dropdown
                          className="form-control solid style-1 "
                          style={{ display: 'flex', alignItems: 'center' }}
                        >
                          <Dropdown.Toggle
                            tabIndex="0"
                            as="div"
                            className="custom-dropdown-toggle fs-16 font-w500 justify-content-between d-flex align-items-center"
                            style={{
                              color:
                                isRemoteAllowed !== null ? 'gray' : 'lightgray',
                              fontSize: '0.875rem !important'
                            }}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter' || e.key === ' ') {
                                e.preventDefault();
                                e.target.click();
                              }
                            }}
                          >
                            {isRemoteAllowed !== null
                              ? (isRemoteAllowed ? 'Yes' : 'No')
                                  .charAt(0)
                                  .toUpperCase() +
                                (isRemoteAllowed ? 'Yes' : 'No').slice(1)
                              : 'Choose a Value'}
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="custom-dropdown-menu dropdown-menu dropdown-menu-end mt-1">
                            {[true, false].map((value) => (
                              <Dropdown.Item
                                key={value.toString()}
                                className={
                                  isRemoteAllowed === value ? 'active' : ''
                                }
                                onClick={() => setIsRemoteAllowed(value)}
                              >
                                {value ? 'Yes' : 'No'}
                              </Dropdown.Item>
                            ))}
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>

                      <div className="col-xl-6  col-md-6 mb-4">
                        <label className="form-label font-w500">
                          Perks
                          <span className="text-danger scale5 ms-2"></span>
                        </label>

                        <input
                          type="text"
                          name="perks"
                          value={perks}
                          onChange={(e) => setPerks(e.target.value)}
                          className="form-control solid  "
                          placeholder="Flexible schedule, bonuses"
                        />
                      </div>
                      <div className="col-xl-6 col-md-6 mb-4">
                        <label className="form-label font-w500">
                          Time Zone
                          <span className="text-danger scale5 ms-2">*</span>
                        </label>
                        <input
                          type="text"
                          name="Time"
                          value={timeZone}
                          onChange={(e) =>
                            setTimeZone(e.target.value.toUpperCase())
                          }
                          className="form-control solid"
                          placeholder="IST"
                          aria-label="name"
                        />
                      </div>
                      <div className="col-xl-6 col-md-6 mb-4">
                        <label className="form-label font-w500">
                          Work Hours
                          <span className="text-danger scale5 ms-2">*</span>
                        </label>
                        <Dropdown
                          className="form-control solid style-1"
                          style={{ display: 'flex', alignItems: 'center' }}
                        >
                          <Dropdown.Toggle
                            tabIndex="0"
                            onKeyDown={(e) => {
                              if (e.key === 'Enter' || e.key === ' ') {
                                e.preventDefault();
                                e.target.click();
                              }
                            }}
                            as="div"
                            className="custom-dropdown-toggle fs-16 font-w500 justify-content-between d-flex align-items-center"
                            style={{
                              color: workHours ? 'gray' : 'lightgray',
                              fontSize: '0.875rem !important'
                            }}
                          >
                            {workHours
                              ? formatStringForFrontend(workHours)
                              : 'Choose a Value'}
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="custom-dropdown-menu dropdown-menu dropdown-menu-end mt-1">
                            {['FULL_TIME', 'PART_TIME'].map((hours) => (
                              <Dropdown.Item
                                key={hours}
                                className={workHours === hours ? 'active' : ''}
                                onClick={() => setWorkHours(hours)}
                              >
                                {formatStringForFrontend(hours)}
                              </Dropdown.Item>
                            ))}
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                      <div className="col-xl-6 col-md-6 mb-4">
                        <label className="form-label font-w500">
                          Work Location Type
                          <span className="text-danger scale5 ms-2">*</span>
                        </label>
                        <Dropdown
                          className="form-control solid style-1 "
                          style={{ display: 'flex', alignItems: 'center' }}
                        >
                          <Dropdown.Toggle
                            tabIndex="0"
                            onKeyDown={(e) => {
                              if (e.key === 'Enter' || e.key === ' ') {
                                e.preventDefault();
                                e.target.click();
                              }
                            }}
                            as="div"
                            className="custom-dropdown-toggle fs-16 font-w500 justify-content-between d-flex align-items-center"
                            style={{
                              color: workLocationType ? 'gray' : 'lightgray',
                              fontSize: '0.875rem !important'
                            }}
                          >
                            {workLocationType
                              ? formatStringForFrontend(workLocationType)
                              : 'Choose a Value'}
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="custom-dropdown-menu dropdown-menu dropdown-menu-end mt-1">
                            {['ON_SITE', 'REMOTE', 'HYBRID'].map((type) => (
                              <Dropdown.Item
                                key={type}
                                className={
                                  workLocationType === type ? 'active' : ''
                                }
                                onClick={() => setWorkLocationType(type)}
                              >
                                {formatStringForFrontend(type)}
                              </Dropdown.Item>
                            ))}
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>

                      <div className="col-xl-6  col-md-6 mb-4">
                        <label className="form-label font-w500">
                          Minimum Yearly Salary (
                          {localStorage.getItem('currency')})
                        </label>
                        <input
                          type="text"
                          value={minimumSalaryDisplay}
                          onChange={handleMinimumSalaryChange}
                          onPaste={(e) =>
                            handleSalaryPaste(
                              e,
                              setMinimumSalary,
                              setMinimumSalaryDisplay
                            )
                          }
                          name="minimumSalary"
                          className="form-control solid"
                          placeholder={`${
                            currencySymbol === 'INR' ? '15,00,000' : '50,000'
                          }`}
                          aria-label="name"
                          min="1"
                        />
                      </div>

                      <div className="col-xl-6  col-md-6 mb-4">
                        <label className="form-label font-w500">
                          Maximum Yearly Salary (
                          {localStorage.getItem('currency')})
                          <span className="text-danger scale5 ms-2">*</span>
                        </label>

                        <input
                          type="text"
                          value={maximumSalaryDisplay}
                          onChange={handleMaximumSalaryChange}
                          onPaste={(e) =>
                            handleSalaryPaste(
                              e,
                              setMaximumSalary,
                              setMaximumSalaryDisplay
                            )
                          }
                          name="maximumSalary"
                          className="form-control solid"
                          placeholder={`${
                            currencySymbol === 'INR' ? '15,00,000' : '50,000'
                          }`}
                          aria-label="name"
                        />
                      </div>

                      <div className="col-xl-6 col-md-6 mb-4">
                        <label className="form-label font-w500">
                          Joining Deadline
                          <span className="text-danger scale5 ms-2">*</span>
                        </label>
                        <Dropdown
                          className="form-control solid style-1 "
                          style={{ display: 'flex', alignItems: 'center' }}
                        >
                          <Dropdown.Toggle
                            tabIndex="0"
                            onKeyDown={(e) => {
                              if (e.key === 'Enter' || e.key === ' ') {
                                e.preventDefault();
                                e.target.click();
                              }
                            }}
                            as="div"
                            className="custom-dropdown-toggle fs-16 font-w500 justify-content-between d-flex align-items-center"
                            style={{
                              color: joiningDeadline ? 'gray' : 'lightgray',
                              fontSize: '0.875rem !important'
                            }}
                          >
                            {joiningDeadline
                              ? `${joiningDeadline} days`
                              : 'Select joining deadline'}
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="custom-dropdown-menu dropdown-menu dropdown-menu-end mt-1">
                            {[
                              '7 days',
                              '15 days',
                              '30 days',
                              '60 days',
                              '90 days'
                            ].map((type) => (
                              <Dropdown.Item
                                key={type}
                                className={
                                  joiningDeadline === type.split(' ')[0]
                                    ? 'active'
                                    : ''
                                }
                                onClick={() =>
                                  setJoiningDeadline(type.split(' ')[0])
                                }
                              >
                                {type}
                              </Dropdown.Item>
                            ))}
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>

                      <div className="col-xl-6 col-md-6 mb-4 position-relative">
                        <label className="form-label font-w500">
                          Company Name
                        </label>
                        <input
                          type="text"
                          name="currentCompany"
                          disabled={loadingOrganizationDetails}
                          value={currentCompany}
                          onChange={(e) => {
                            const value = e.target.value;
                            setCurrentCompany(value);
                          }}
                          className={'form-control solid'}
                          placeholder="Thinkscoop"
                          aria-label="company-name"
                          style={
                            loadingOrganizationDetails
                              ? {
                                  backgroundColor: 'gainsboro',
                                  cursor: 'not-allowed'
                                }
                              : {}
                          }
                        ></input>
                        {loadingOrganizationDetails && (
                          <Spinner
                            size="small"
                            animation="border"
                            style={{
                              color: '#858585',
                              width: '1rem',
                              height: '1rem',
                              position: 'absolute',
                              top: '40px',
                              right: '30px'
                            }}
                          />
                        )}
                      </div>

                      <div className="col-xl-12 mb-4">
                        <label className="form-label font-w500">
                          Description:
                          <span className="text-danger scale5 ms-2">*</span>
                        </label>
                        <textarea
                          className="form-control solid"
                          placeholder="We are rapidly expanding our team! We are looking for highly motivated software engineer who..."
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                          rows="4"
                          aria-label="With textarea"
                          name="jobDescriptionSummary"
                        ></textarea>
                      </div>
                      <div className="col-xl-12 mb-4">
                        <label className="form-label font-w500">
                          Responsibilities:
                          <span className="text-danger scale5 ms-2">*</span>
                        </label>
                        <textarea
                          className="form-control solid"
                          value={responsibilities}
                          placeholder="Collaborating with management, development team members and customers to identify end..."
                          onChange={handleResponsibilityChange}
                          onPaste={handleResponsibilityPaste}
                          rows="4"
                          aria-label="With textarea"
                          name="responsibilities"
                        ></textarea>
                      </div>
                      <div className="qualifications col-xl-12 mb-4">
                        <label className="form-label font-w500">
                          Qualifications
                          <span className="text-danger scale5 ms-2">*</span>
                        </label>
                        <textarea
                          className="form-control solid"
                          placeholder="A degree in computer science or equivalent experience. 4+ years working with Ruby on Rails OR..."
                          value={qualifications}
                          onChange={handleQualificationsChange}
                          onPaste={handleQualificationsPaste}
                          name="qualifications"
                          aria-label="name"
                          rows="4"
                          style={{
                            color: 'gray',
                            '::placeholder': { color: 'lightgray' }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="card-footer text-end">
                    <div>
                      <button
                        className="btn btn-danger light me-3"
                        onClick={handleClose}
                        tabIndex="-1"
                      >
                        Close
                      </button>
                      <button
                        onClick={handleSubmit}
                        className="btn btn-primary"
                        disabled={jobId && !hasChanges()}
                      >
                        {isCloneJob
                          ? 'Create Job'
                          : jobId
                            ? 'Save Changes'
                            : 'Create Job'}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`tab-pane fade ${
                  activeTab === 'advanced' ? 'show active' : ''
                }`}
                id="advanced"
                role="tabpanel"
              >
                <AdvancedJobConfig
                  setTestProcesses={setTestProcesses}
                  testProcesses={testProcesses}
                  loading={loading}
                  setLoading={setLoading}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default NewJob;
