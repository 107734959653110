import axios from 'axios';
import swal from 'sweetalert';
import { loginConfirmedAction, Logout } from '../store/actions/AuthActions';
import { jwtDecode } from 'jwt-decode';
import logoDark from '../images/logo-dark.jpg';
import logoIcon from '../images/logo.ico';

export function signUp(email, password) {
  //axios call
  const postData = {
    email,
    password,
    returnSecureToken: true
  };
  return axios.post(
    'https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=AIzaSyD3RPAp3nuETDn9OQimqn_YF6zdzqWITII',
    postData
  );
}

export function login(email, password) {
  const postData = {
    email,
    password
    // role: "ADMIN",
    // returnSecureToken: true,
  };
  return axios.post(
    `${process.env.REACT_APP_DEV_API}/v1/iam/sign-in`,
    postData
  );
}

export function formatError(errorResponse) {
  switch (errorResponse.error.message) {
    case 'EMAIL_EXISTS':
      //return 'Email already exists';
      swal('Oops', 'Email already exists', 'error');
      break;
    case 'EMAIL_NOT_FOUND':
      //return 'Email not found';
      swal('Oops', 'Email not found', 'error', { button: 'Try Again!' });
      break;
    case 'INVALID_PASSWORD':
      //return 'Invalid Password';
      swal('Oops', 'Invalid Password', 'error', { button: 'Try Again!' });
      break;
    case 'USER_DISABLED':
      return 'User Disabled';

    default:
      return '';
  }
}

export async function fetchLogos() {
  const userDetails = JSON.parse(localStorage.getItem('userDetails'));
  const orgId = userDetails?.user?.organizationId;
  try {
    const accessToken = userDetails?.session.accessToken || null;
    if (!accessToken) {
      throw new Error('Access token not found');
    }

    const response = await fetch(
      `${process.env.REACT_APP_DEV_API}/v1/organization/${orgId}`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`
        }
      }
    );

    if (!response.ok) {
      throw new Error('Failed to fetch organization details');
    }

    const data = await response.json();
    const logoData = data.data.organization.logo;
    const currency = data.data.organization.currency;

    localStorage.setItem('currency', currency);

    localStorage.setItem(
      'horizontalLogo',
      logoData.horizontalLogoSignedUrl || logoDark
    );
    localStorage.setItem(
      'squareLogo',
      logoData.squareLogoSignedUrl || logoIcon
    );
    localStorage.setItem(
      'organizationUniqueName',
      data.data.organization.uniqueName
    );

    window.dispatchEvent(new Event('logoUpdated'));
  } catch (error) {
    console.error('Error fetching organization details:', error);
    localStorage.setItem('horizontalLogo', logoDark);
    localStorage.setItem('squareLogo', logoIcon);
    localStorage.setItem('currency', 'INR');
  }
}

export function saveTokenInLocalStorage(tokenDetails) {
  //   tokenDetails.expireDate = new Date(
  //     new Date().getTime() + tokenDetails.expiresIn * 1000
  //   );
  const { role, organizationId } = tokenDetails.user;

  if (role === 'SUPERADMIN') {
    localStorage.setItem('superAdminUser', JSON.stringify(tokenDetails));
  } else if (role === 'ADMIN') {
    if (!organizationId) {
      throw new Error('Unable to login. Please contact customer support');
    }
    localStorage.setItem('userDetails', JSON.stringify(tokenDetails));
  } else {
    throw new Error('Invalid user role');
  }
}

export function decodeTokenRole(token) {
  try {
    const decode = jwtDecode(token);
    return decode.user.role;
  } catch (error) {
    console.log('dcode', error);
    return null;
  }
}

export function runLogoutTimer(dispatch, timer, navigate) {
  setTimeout(() => {
    //dispatch(Logout(history));
    dispatch(Logout(navigate));
  }, timer);
}

export function checkAutoLogin(dispatch, navigate) {
  console.log('autologin');
  const tokenDetailsObj =
    JSON.parse(localStorage.getItem('superAdminUser')) ||
    JSON.parse(localStorage.getItem('userDetails'));
  console.log(tokenDetailsObj);
  //   let tokenDetails = "";
  if (!tokenDetailsObj || (Object.keys(tokenDetailsObj).length = 0)) {
    dispatch(Logout(navigate));
    return;
  }

  //   tokenDetails = JSON.parse(tokenDetailsObj);
  //   let expireDate = new Date(tokenDetails.expireDate);
  //   let todaysDate = new Date();

  //   if (todaysDate > expireDate) {
  //     dispatch(Logout(navigate));
  //     return;
  //   }

  dispatch(loginConfirmedAction(tokenDetailsObj));
  // navigate("/dashboard");

  //   const timer = expireDate.getTime() - todaysDate.getTime();
  //   runLogoutTimer(dispatch, timer, navigate);
}

export function forgotPassword(email) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_DEV_API}/v1/iam/otp`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            email,
            role: 'ADMIN'
          })
        }
      );

      const data = await response.json();

      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(new Error(data.message || 'An error occurred'));
      }
    } catch (error) {
      console.error('Error while sending OTP:', error);
      reject(new Error('An error occurred, please try again.'));
    }
  });
}

export function resetPassword(email, otp, password, recaptchaValue) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_DEV_API}/v1/iam/reset-password`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            email,
            otp,
            newPassword: password,
            gRecaptchaResponse: recaptchaValue
          })
        }
      );

      const data = await response.json();

      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(new Error(data.message || 'An error occurred'));
      }
    } catch (error) {
      console.error('Error while resetting the password:', error);
      reject(new Error('An error occurred, please try again.'));
    }
  });
}

export function changePassword(oldPassword, newPassword) {
  return new Promise(async (resolve, reject) => {
    try {
      const accessToken =
        localStorage.getItem('superAdminUser') ||
        localStorage.getItem('userDetails')
          ? JSON.parse(
              localStorage.getItem('superAdminUser') ||
                localStorage.getItem('userDetails')
            ).session.accessToken
          : null;

      if (!accessToken) {
        throw new Error('Access token not found in local storage');
      }

      const response = await fetch(
        `${process.env.REACT_APP_DEV_API}/v1/iam/change-password`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`
          },
          body: JSON.stringify({ oldPassword, newPassword })
        }
      );

      const data = await response.json();

      if (data.message.includes('success')) {
        resolve(data);
      } else {
        reject(new Error(data.message || 'An error occurred'));
      }
    } catch (error) {
      console.error('Error while changing the password:', error);
      reject(new Error('An error occurred, please try again.'));
    }
  });
}
