import React, { useEffect, useState, useRef, useCallback } from 'react';
import './style.css';
import { toast } from 'react-toastify';
import Search from '../Jobick/Jobs/ui/search';
import { Button, Card, Form, Spinner } from 'react-bootstrap';
import moment from 'moment';
import {
  formatNumberWithCommas,
  getItemsPerPage
} from '../../utils/CommonFunctions';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import { IoSearch } from 'react-icons/io5';

// Memoize the SearchBar component
const SearchBar = React.memo(({ searchVal, setSearchVal }) => {
  const inputRef = useRef(null);

  const handleChange = (e) => {
    setSearchVal(e.target.value);
  };

  return (
    <div className="search-container">
      <input
        ref={inputRef}
        type="text"
        className="search-input"
        placeholder="Search transactions..."
        value={searchVal}
        onChange={handleChange}
        data-has-value={searchVal.length > 0}
      />
      <i className="fas fa-search search-icon"></i>
    </div>
  );
});

const Billing = () => {
  const [billingDetails, setBillingDetails] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [pageVal, setPageVal] = useState(1); // for value of pagination
  const [totalPages, setTotalPages] = useState(1);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isLoadingPage, setIsLoadingPage] = useState(false);
  const [isLoadingTransactions, setIsLoadingTransactions] = useState(false);
  const [totalTransactions, setTotalTransactions] = useState();
  const transactionsPerPage = 5;

  const accessToken = localStorage.getItem('userDetails')
    ? JSON.parse(localStorage.getItem('userDetails')).session.accessToken
    : null;

  const userDetails = JSON.parse(localStorage.getItem('userDetails'));
  const orgId = userDetails?.user?.organizationId;

  const [notificationSettings, setNotificationSettings] = useState({
    creditUsage: {
      enabled: true,
      threshold: 100
    },
    amountDueThreshold: {
      enabled: true,
      threshold: 500
    },
    weeklyUsageReport: {
      enabled: true
    },
    unusualActivity: {
      enabled: true
    },
    paymentReminders: {
      enabled: true,
      daysBeforeDue: 5
    }
  });

  const handleNotificationChange = (key, field, value) => {
    setNotificationSettings((prev) => ({
      ...prev,
      [key]: {
        ...prev[key],
        [field]: value
      }
    }));
  };

  const handleNotificationSubmit = async (e) => {
    e.preventDefault();

    // Simulating API call
    try {
      // Add API call here
      await new Promise((resolve) => setTimeout(resolve, 1000));

      toast.success('Notification preferences updated successfully!', {
        position: 'bottom-right',
        autoClose: 3000
      });
    } catch (error) {
      toast.error('Failed to update notification preferences');
    }
  };

  const openLoader = () => {
    const loaderEvent = new CustomEvent('toggleLoader', {
      detail: {
        action: 'open',
        title: 'Fetching billing details',
        fullSize: true
      }
    });
    window.dispatchEvent(loaderEvent);
  };

  const closeLoader = () => {
    const loaderEvent = new CustomEvent('toggleLoader', {
      detail: { action: 'close' }
    });
    window.dispatchEvent(loaderEvent);
  };

  const handlePreviousPageClick = () => {
    if (pageVal === 1) {
      return;
    }
    setPageVal(pageVal - 1);
  };

  const handleNextPageClick = () => {
    if (pageVal >= totalPages) {
      return;
    }
    setPageVal(pageVal + 1);
  };

  const handleSearch = useCallback((value) => {
    setSearchQuery(value);
    setPageVal(1);
  }, []);

  const renderTransactionTableBody = () => {
    if (isLoadingTransactions) {
      return (
        <tr>
          <td colSpan={4} className="text-center">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </td>
        </tr>
      );
    } else {
      const transactions = billingDetails.transactions;
      return transactions.map(({ _id, timestamp, remarks, points }) => (
        <tr key={_id} style={{ height: '4.5rem' }}>
          <td style={{ width: '4rem' }} className="text-truncate">
            {moment(timestamp).format('YYYY-MM-DD')}
          </td>
          <td
            style={{ maxWidth: '14rem' }}
            className="d-flex align-items-center justify-content-between"
          >
            <div className="transaction-id">
              <span className="text-muted">
                {`${_id.slice(0, 4)}...${_id.slice(-8)}`}
              </span>
              <button
                className="copy-button"
                onClick={() => {
                  navigator.clipboard.writeText(_id);
                  toast.success('Transaction ID copied!', {
                    position: 'bottom-right',
                    autoClose: 2000
                  });
                }}
                title="Copy Transaction ID"
              >
                <i className="fas fa-copy"></i>
              </button>
            </div>
          </td>
          <td
            style={{ maxWidth: '34rem' }}
            className="text-truncate"
            title={remarks}
          >
            {remarks}
          </td>
          <td
            style={{ maxWidth: '1rem', textAlign: 'center' }}
            className="text-truncate"
          >
            <span className="credits-value negative-value">
              -{Math.abs(points)}
            </span>
          </td>
        </tr>
      ));
    }
  };

  const createUrl = () => {
    let url = `${
      process.env.REACT_APP_DEV_API
    }/v1/organization/${orgId}/billing?limit=${getItemsPerPage()}`;
    if (searchQuery) {
      url = url + `&search=${searchQuery}`;
    }
    if (pageVal) {
      url = url + `&page=${pageVal}`;
    }

    return url;
  };

  const fetchBillingDetails = async () => {
    try {
      setIsLoadingTransactions(true);
      const response = await fetch(createUrl(), {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`
        }
      });

      if (!response.ok) {
        toast.error('Something went wrong');
        return;
      }

      const { data } = await response.json();
      setTotalPages(data.totalPages);
      setTotalTransactions(200);
      setBillingDetails(data);
    } catch (error) {
      console.log('Error fetching application details', error);
      toast.error('Something went wrong');
    } finally {
      setIsLoadingTransactions(false);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 940);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    fetchBillingDetails();
  }, [searchQuery, pageVal]);

  useEffect(() => {
    (async () => {
      try {
        setIsLoadingPage(true);
        const response = await fetch(createUrl(), {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`
          }
        });

        if (!response.ok) {
          toast.error('Something went wrong');
          return;
        }

        const { data } = await response.json();
        setTotalPages(data.totalPages);
        setTotalTransactions(200);
        setBillingDetails(data);
      } catch (error) {
        console.log('Error fetching application details', error);
        toast.error('Something went wrong');
      } finally {
        setIsLoadingPage(false);
      }
    })();
  }, []);

  const pgItem1 =
    pageVal === 1
      ? 1
      : pageVal >= totalPages && totalPages > 2
        ? totalPages - 2
        : pageVal - 1;
  const pgItem2 =
    pageVal === 1
      ? 2
      : pageVal >= totalPages && totalPages > 2
        ? totalPages - 1
        : pageVal;
  const pgItem3 =
    pageVal === 1
      ? 3
      : pageVal >= totalPages && totalPages > 2
        ? totalPages
        : pageVal + 1;

  return (
    <div class="d-flex w-100 flex-column align-items-start gap-5">
      <div className="billing-header">
        <div className="billing-titles">
          <h1 className="billing-page-title">Billing Dashboard</h1>
          <p className="mb-0">
            Manage your billing information and transactions seamlessly.
          </p>
        </div>
      </div>

      <div className="alert custom-alert d-flex align-items-center w-100">
        <div className="d-flex align-items-center gap-4 py-2">
          <div className="alert-icon-wrapper">
            <i className="fas fa-bell fa-lg"></i>
          </div>
          <div className="alert-content">
            <p className="mb-0">
              Don't worry about extra costs - we'll alert you when your credits
              are running low.
            </p>
          </div>
        </div>
      </div>

      {isLoadingPage ? (
        <div className="d-flex w-100 justify-content-center">
          <Spinner />
        </div>
      ) : (
        <>
          <div
            className={`d-flex gap-3 w-100 justify-content-between ${
              isMobile ? 'flex-column' : 'billing-cards'
            }`}
          >
            <Card className={` mb-0 h-100 ${isMobile ? 'w-100' : 'w-50'}`}>
              <Card.Body className="bg-primary text-white rounded d-flex flex-column gap-2">
                <Card.Title className="text-white mb-0 d-flex align-items-center gap-2">
                  <i className="fas fa-coins fa-fw"></i>
                  Current Credits
                </Card.Title>
                <Card.Text className="display-5 text-white fw-bold">
                  {billingDetails && billingDetails.currentCredits
                    ? formatNumberWithCommas(
                        Math.max(0, billingDetails.currentCredits)
                      )
                    : '-'}
                </Card.Text>
                <Card.Text className="mb-0 text-white mt-2">
                  <i className="fas fa-info-circle me-2"></i>
                  Available credits for your transactions
                </Card.Text>
                <Card.Text className="mb-0 text-white mt-2">
                  <i className="fas fa-bell me-2"></i>
                  Don't worry about extra costs - we'll alert you when your
                  credits are running low.
                </Card.Text>
              </Card.Body>
            </Card>
            <Card
              className={`mb-0 h-100 ${isMobile ? 'w-100' : 'w-50'} redeem-coupon-card`}
            >
              <Card.Body className="rounded d-flex flex-column gap-3">
                <Card.Title className="text-dark mb-0 d-flex align-items-center gap-2">
                  <i className="fas fa-ticket-alt fa-fw"></i>
                  Redeem Coupon
                </Card.Title>
                <Form className="coupon-form">
                  <Form.Control
                    type="text"
                    placeholder="Enter coupon code"
                    className="coupon-input"
                  />
                  <Button disabled className="redeem-button">
                    <i className="fas fa-gift"></i>
                    <span>Redeem</span>
                  </Button>
                </Form>
                <Card.Text className="text-muted">
                  <i className="fas fa-info-circle me-2"></i>
                  Have a coupon? Redeem it here to get additional credits
                </Card.Text>
              </Card.Body>
            </Card>
          </div>

          {billingDetails && billingDetails.transactions && (
            <div className="d-flex flex-column gap-4 w-100">
              <div
                className={`w-100 d-flex justify-content-between gap-2 ${isMobile ? 'flex-column align-items-start' : 'align-items-center'}`}
              >
                <p className="section-heading">Usage and Transactions</p>
                <SearchBar
                  searchVal={searchQuery}
                  setSearchVal={handleSearch}
                />
              </div>
              <div class="table-responsive">
                <table
                  id="job-table"
                  className="table table-fixed display dataTablesCard mb-4 job-table card-table dataTable no-footer w-100 position-relative"
                  style={{
                    height:
                      billingDetails.transactions.length === 0 ? '500px' : ''
                  }}
                >
                  <thead>
                    <tr className="user-select-none ">
                      <th style={{ width: '4rem' }}>Date</th>
                      <th style={{ width: '14rem' }}>Transaction ID</th>
                      <th style={{ width: '34rem' }}>Description</th>
                      <th style={{ width: '1rem', textAlign: 'left' }}>
                        Credits Used
                      </th>
                    </tr>
                  </thead>
                  {billingDetails.transactions.length === 0 ? (
                    <div className="position-absolute top-50 start-50 translate-middle d-flex flex-column align-items-center mt-5 w-100">
                      <IoSearch size={120} color="#878787B7" />
                      <h2>No results found</h2>
                      <h3 style={{ color: '#828282' }}>
                        Change your Job and try again
                      </h3>
                    </div>
                  ) : (
                    <tbody>{renderTransactionTableBody()}</tbody>
                  )}
                </table>
              </div>

              <nav
                className={`d-flex ${
                  isMobile
                    ? 'flex-column align-items-center'
                    : 'justify-content-between'
                }`}
              >
                <p className="dataTables_info">{`Showing ${
                  billingDetails.transactions.length !== 0
                    ? transactionsPerPage * (pageVal - 1) + 1
                    : 0
                } to ${
                  billingDetails.transactions
                    ? transactionsPerPage * (pageVal - 1) +
                      billingDetails.transactions.length
                    : 0
                } of ${totalPages} entries`}</p>
                <ul className="pagination pagination-circle justify-content-center">
                  <li className="page-item page-indicator">
                    <button
                      onClick={handlePreviousPageClick}
                      disabled={pageVal === 1}
                      className={' page-link'}
                      style={{
                        backgroundColor: pageVal === 1 && '#ECECEC',
                        cursor: pageVal === 1 && 'not-allowed'
                      }}
                    >
                      <FaAngleLeft
                        style={{ color: pageVal === 1 && '#616161' }}
                      />
                    </button>
                  </li>
                  {pgItem1 > 1 ? (
                    <li className="page-item d-flex align-items-end">
                      <label className="fs-4">...</label>
                    </li>
                  ) : (
                    <div style={{ width: '15px' }} />
                  )}
                  <li
                    className={`page-item ${pgItem1 === pageVal && 'active'}`}
                    onClick={() => {
                      setPageVal(pgItem1);
                    }}
                  >
                    <Button className="page-link">{pgItem1}</Button>
                  </li>
                  {totalPages > 1 && (
                    <li
                      className={`page-item ${pgItem2 === pageVal && 'active'}`}
                      onClick={() => {
                        setPageVal(pgItem2);
                      }}
                    >
                      <Button className="page-link">{pgItem2}</Button>
                    </li>
                  )}
                  {totalPages > 2 && (
                    <li
                      className={`page-item ${pgItem3 === pageVal && 'active'}`}
                      onClick={() => {
                        setPageVal(pgItem3);
                      }}
                    >
                      <Button className="page-link">{pgItem3}</Button>
                    </li>
                  )}
                  {pgItem3 < totalPages ? (
                    <li className="page-item d-flex align-items-end">
                      <label className="fs-4">...</label>
                    </li>
                  ) : (
                    <div style={{ width: '15px' }} />
                  )}
                  <li className="page-item page-indicator">
                    <button
                      onClick={handleNextPageClick}
                      disabled={pageVal >= totalPages}
                      className="page-link"
                      style={{
                        backgroundColor: pageVal >= totalPages && '#ECECEC',
                        cursor: pageVal >= totalPages && 'not-allowed'
                      }}
                    >
                      <FaAngleRight
                        style={{ color: pageVal >= totalPages && '#616161' }}
                      />
                    </button>
                  </li>
                </ul>
              </nav>
            </div>
          )}

          {billingDetails && billingDetails.totalDue && (
            <div className="d-flex flex-column w-100 gap-3">
              <p className="section-heading">Amount Due</p>
              <Card className="amount-due-card">
                <Card.Body>
                  <div className="amount-header">
                    <div className="amount-value">
                      <span className="amount-label">Total Due Amount</span>
                      <span className="amount-number">
                        $
                        {formatNumberWithCommas(
                          (billingDetails.totalDue?.amount || 0) +
                            Math.abs(
                              Math.min(0, billingDetails.currentCredits || 0)
                            )
                        )}
                      </span>
                    </div>
                    <div className="payment-notice">
                      <i className="fas fa-clock"></i>
                      <span>Payment Due</span>
                    </div>
                  </div>

                  <div className="payment-message-container">
                    <div className="payment-message">
                      <i className="fas fa-info-circle"></i>
                      <span style={{ color: '#ffffff' }}>
                        Kindly clear the monthly due payment within the first 15
                        days of the next month.
                      </span>
                    </div>

                    <div className="payment-warning">
                      <i className="fas fa-exclamation-circle"></i>
                      <span>
                        Your account will be automatically blocked if payment is
                        not received by then.
                      </span>
                    </div>
                  </div>

                  <Button
                    className="payment-button"
                    disabled={!billingDetails.totalDue?.amount}
                    style={{ color: '#4e95ff' }}
                  >
                    <span style={{ color: 'inherit' }}>Proceed to Payment</span>
                    <i
                      className="fas fa-arrow-right"
                      style={{ color: 'inherit' }}
                    ></i>
                  </Button>
                </Card.Body>
              </Card>
            </div>
          )}

          <div className="d-flex flex-column w-100 gap-3">
            <p className="section-heading">Notification Preferences</p>
            <Card className="notification-card">
              <Card.Body>
                <form
                  onSubmit={handleNotificationSubmit}
                  className="notification-form"
                >
                  <div className="notification-grid">
                    {/* Credit Usage Alert */}
                    <div className="notification-item">
                      <div className="notification-header">
                        <div className="notification-icon credits">
                          <i className="fas fa-coins"></i>
                        </div>
                        <div className="notification-title">
                          <h4>Credit Usage Alert</h4>
                          <p>
                            Get notified when your free credits are exhausted
                            and you start incurring charges. This helps you
                            track when you begin accumulating billable usage in
                            your monthly due amount.
                          </p>
                        </div>
                        <Form.Check
                          type="switch"
                          id="credit-usage-switch"
                          checked={notificationSettings.creditUsage.enabled}
                          onChange={(e) =>
                            handleNotificationChange(
                              'creditUsage',
                              'enabled',
                              e.target.checked
                            )
                          }
                          className="custom-switch"
                        />
                      </div>
                      {notificationSettings.creditUsage.enabled && (
                        <div className="threshold-input">
                          <div className="input-wrapper credits">
                            <input
                              type="number"
                              value={notificationSettings.creditUsage.threshold}
                              onChange={(e) =>
                                handleNotificationChange(
                                  'creditUsage',
                                  'threshold',
                                  e.target.value
                                )
                              }
                              min="0"
                            />
                          </div>
                          <div className="threshold-label">
                            credits - alert when below this
                          </div>
                        </div>
                      )}
                    </div>

                    {/* Amount Due Alert */}
                    <div className="notification-item">
                      <div className="notification-header">
                        <div className="notification-icon amount">
                          <i className="fas fa-dollar-sign"></i>
                        </div>
                        <div className="notification-title">
                          <h4>Amount Due Alert</h4>
                          <p>
                            Get notified when your monthly due amount exceeds a
                            certain threshold. Helps you monitor your spending
                            and avoid unexpected bills.
                          </p>
                        </div>
                        <Form.Check
                          type="switch"
                          id="amount-due-switch"
                          checked={
                            notificationSettings.amountDueThreshold.enabled
                          }
                          onChange={(e) =>
                            handleNotificationChange(
                              'amountDueThreshold',
                              'enabled',
                              e.target.checked
                            )
                          }
                          className="custom-switch"
                        />
                      </div>
                      {notificationSettings.amountDueThreshold.enabled && (
                        <div className="threshold-input">
                          <div className="input-wrapper amount">
                            <span className="currency">$</span>
                            <input
                              type="number"
                              value={
                                notificationSettings.amountDueThreshold
                                  .threshold
                              }
                              onChange={(e) =>
                                handleNotificationChange(
                                  'amountDueThreshold',
                                  'threshold',
                                  e.target.value
                                )
                              }
                              min="0"
                            />
                          </div>
                          <div className="threshold-label">
                            threshold amount
                          </div>
                        </div>
                      )}
                    </div>

                    {/* Weekly Usage Report */}
                    <div className="notification-item">
                      <div className="notification-header">
                        <div className="notification-icon report">
                          <i className="fas fa-chart-line"></i>
                        </div>
                        <div className="notification-title">
                          <h4>Weekly Usage Report</h4>
                          <p>
                            Receive detailed weekly reports about your credit
                            usage, spending patterns, and account activity to
                            better manage your resources.
                          </p>
                        </div>
                        <Form.Check
                          type="switch"
                          id="weekly-report-switch"
                          checked={
                            notificationSettings.weeklyUsageReport.enabled
                          }
                          onChange={(e) =>
                            handleNotificationChange(
                              'weeklyUsageReport',
                              'enabled',
                              e.target.checked
                            )
                          }
                          className="custom-switch"
                        />
                      </div>
                    </div>

                    {/* Unusual Activity */}
                    <div className="notification-item">
                      <div className="notification-header">
                        <div className="notification-icon security">
                          <i className="fas fa-shield-alt"></i>
                        </div>
                        <div className="notification-title">
                          <h4>Unusual Activity</h4>
                          <p>
                            Get alerts when we detect unusual patterns in your
                            credit usage or spending to help prevent
                            unauthorized use.
                          </p>
                        </div>
                        <Form.Check
                          type="switch"
                          id="unusual-activity-switch"
                          checked={notificationSettings.unusualActivity.enabled}
                          onChange={(e) =>
                            handleNotificationChange(
                              'unusualActivity',
                              'enabled',
                              e.target.checked
                            )
                          }
                          className="custom-switch"
                        />
                      </div>
                    </div>

                    {/* Payment Reminders */}
                    <div className="notification-item">
                      <div className="notification-header">
                        <div className="notification-icon reminder">
                          <i className="fas fa-bell"></i>
                        </div>
                        <div className="notification-title">
                          <h4>Payment Reminders</h4>
                          <p>
                            Get reminded before your monthly payment is due.
                            Helps you avoid any service interruptions due to
                            overdue payments.
                          </p>
                        </div>
                        <Form.Check
                          type="switch"
                          id="payment-reminders-switch"
                          checked={
                            notificationSettings.paymentReminders.enabled
                          }
                          onChange={(e) =>
                            handleNotificationChange(
                              'paymentReminders',
                              'enabled',
                              e.target.checked
                            )
                          }
                          className="custom-switch"
                        />
                      </div>
                      {notificationSettings.paymentReminders.enabled && (
                        <div className="threshold-input">
                          <div className="input-wrapper days">
                            <input
                              type="number"
                              value={
                                notificationSettings.paymentReminders
                                  .daysBeforeDue
                              }
                              onChange={(e) =>
                                handleNotificationChange(
                                  'paymentReminders',
                                  'daysBeforeDue',
                                  e.target.value
                                )
                              }
                              min="1"
                              max="30"
                            />
                          </div>
                          <div className="threshold-label">days before due</div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="notification-actions">
                    <Button type="submit" className="save-notifications">
                      <i className="fas fa-save"></i>
                      Save Preferences
                    </Button>
                  </div>
                </form>
              </Card.Body>
            </Card>
          </div>
        </>
      )}
    </div>
  );
};

export default Billing;
